<template>
    <div class="wrapper py-5">
        <v-container
            fluid
            class="white rounded"
        >
            <v-row class="mb-5" />

            <v-row>
                <v-col
                    v-if="search_fields"
                    cols="12"
                    class="sticky-filter pb-1"
                >
                    <the-main-filter
                        :search-fields="search_fields"
                        :search-values="searchOptions"
                        :active-preset="searchOptions.preset"
                        :presets="getterPresets"
                        @filterTable="onFilterTable"
                    >
                        <template
                            slot="left_panel"
                            slot-scope="{ events: { updateShortcats } }"
                        >
                            <the-filter-preset
                                v-if="!getterPresetsLoading"
                                :active-preset="searchOptions.preset"
                                :presets="getterPresets"
                                @update:shortcat="updateShortcats"
                            />
                            <v-progress-circular
                                v-else
                                :size="30"
                                :width="4"
                                color="primary"
                                indeterminate
                            />
                        </template>
                    </the-main-filter>
                </v-col>
                <v-col
                    cols="12"
                    class="pt-0"
                >
                    <the-order-table
                        v-model="selected"
                        :table-items="getterOrderList"
                        :total="getterTotal"
                        :current-page="getterCurrentPage"
                        :last-page="getterLastPage"
                        :loading="getterOrderListLoading"
                        :loading_totals="getterTotalsLoading"
                        :per_page="searchOptions.per_page"
                        :current-item="currentItem"
                        :focused-orderid="focusedOrderid"
                        @selected="onSelected"
                        @paginate="onPaginate"
                        @sortTable="onSortTable"
                        @actionRow="onActionRow"
                        @showBids="onShowBids"
                        @showNotes="onShowNotes"
                        @showTestimonials="onShowTestimonials"
                        @showBatches="onShowBatches"
                        @updatePerpage="onFilterTable"
                        @showDetails="onShowDetails"
                    />
                </v-col>
            </v-row>

            <toolbar-menu
                v-if="selected.length"
                :toolbars="toolbars"
                @toolbarActivated="onToolbarActivated"
            />

            <component
                :is="modalComponent"
                :title="modal.title"
                :description="modal.description"
                :input-label="modal.inputLabel"
                :current="currentItem"
                :loading="action_loading"
                :allowed-file-types="allowedFileTypes"
                @closeModal="onCloseModal"
                @confirmModal="onConfirmModal"
            />

            <!-- // Bids -->
            <bids-viewer
                v-if="showBids"
                :bids-info="bidsInfo"
                @assignBid="onAssignBid"
                @closeBids="onCloseBids"
                @deleteBid="onDeleteBid"
            />

            <!-- DETAILS -->

            <details-viewer
                v-if="showDetails"
                :order_id="detailsOrderid"
                @closeDetails="onCloseDetails"
            />

            <!-- NOTES -->
            <order-notes-viewer
                v-if="showNotes"
                :order_id="notesOrderid"
                @closeNotes="onCloseNotes"
                @deleteNote="onDeleteNote"
            />

            <!-- TESTIMONIALS -->
            <order-testimonials-viewer
                v-if="showTestimonials"
                :orderid="testimonialsOrderid"
                @closeTestimonials="onCloseTestimonials"
            />

            <!-- Batch viewer -->
            <batch-viewer
                v-if="showBatchPanel"
                :batchid="batchPanelId"
                @closeBatches="onCloseBatches"
            />
        </v-container>
    </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex';
import { eventBus } from '@/helpers/event-bus/'

// PAGE COMPONENT
import TheMainFilter from '@/components/shared/TableFilters/TheMainFilter'
import TheFilterPreset from '@/components/shared/TableFilters/TheFilterPreset'
import ToolbarMenu from '@/components/shared/Tables/ToolbarMenu'

// MODALS
import ModalTextarea from '@/components/shared/Modals/ModalTextarea'
import ModalCreateTicketClient from '@/components/shared/Modals/ModalCreateTicketClient'
import ModalCreateTicketWriter from '@/components/shared/Modals/ModalCreateTicketWriter'
import ModalAlert from '@/components/shared/Modals/ModalAlert'
import ModalAssign from '@/components/shared/Modals/ModalAssign'
import ModalDeadline from '@/components/shared/Modals/ModalDeadline'
import ModalFee from '@/components/shared/Modals/ModalFee'
import ModalUpload from '@/components/shared/Modals/ModalUpload'
import ModalTransfer from '@/components/shared/Modals/ModalTransfer'
import ModalDropWriter from '@/components/shared/Modals/ModalDropWriter'

// TABLE COMPONENT
import TheOrderTable from '@/components/Orders/OrderTable/TheOrderTable'

import BidsViewer from '@/components/SidePanels/BidsViewer'
import OrderTestimonialsViewer from '@/components/SidePanels/TestimonialsViewer'
import OrderNotesViewer from '@/components/SidePanels/OrderNotesViewer'
import BatchViewer from '@/components/SidePanels/BatchViewer'

import {
    ALLOWED_FILE_TYPES, MASS_ORDER_TOOLBARS
} from '@/constants/Order'

import {
    searchFieldsHelper
} from '@/services/searchFieldsHelpers'

import {
    ACTION_CREATE_BOOKMARK
} from '@/store/modules/bookmarks/action-types';

import {
    ACTION_ASIGN_WRITER,
    ACTION_CREATE_NOTE,
    ACTION_GET_ORDER_LIST,
    ACTION_GET_ORDER_PRESETS,
    ACTION_GET_ORDER_TOTALS,
    ACTION_ORDER_DEADLINE,
    ACTION_ORDER_DROP,
    ACTION_ORDER_FEE,
    ACTION_ORDER_LIST_LIVE_UPDATE,
    ACTION_ORDER_REVISION,
    ACTION_UPLOAD_ORDER_FILE,
    ACTION_NOTIFY_COMPLETE_ORDER,
    ACTION_NOTIFY_UNPAID_ORDER,
    ACTION_ORDER_CHANGE_STATUS
} from '@/store/modules/orders/action-types';

import {
    ASSIGN_BID,
    DELETE_BID,
    DELETE_NOTE_COUNTER,
    CLEAR_ORDER_LIST
} from '@/store/modules/orders/mutation-types';

import {
    ACTION_CREATE_TICKET,
    ACTION_CREATE_TICKET_WRITER
} from '@/store/modules/support/action-types';
import DetailsViewer from '@/components/SidePanels/DetailsViewer.vue';
import Helper from '@/helpers/functions';

const {
    mapActions: mapAdminActions,
    mapMutations: mapOrderMutations
} = createNamespacedHelpers('orders');

const {
    mapActions: mapBookmarkActions
} = createNamespacedHelpers('bookmarks');

const {
    mapActions: mapSupportActions
} = createNamespacedHelpers('support');

export default {
    components: {
        TheMainFilter,
        TheFilterPreset,
        ToolbarMenu,
        TheOrderTable,
        BidsViewer,
        OrderNotesViewer,
        BatchViewer,
        // MODALS
        ModalAssign,
        ModalTextarea,
        ModalCreateTicketClient,
        ModalCreateTicketWriter,
        ModalAlert,
        ModalDeadline,
        ModalFee,
        ModalUpload,
        ModalTransfer,
        ModalDropWriter,
        OrderTestimonialsViewer,
        DetailsViewer
    },
    data() {
        return {
            isFirstLoading: true,
            savedScroll: { x: 0, y: 0 },
            timerID: null,
            //
            selected: [],
            actionName: '',
            modal: {},
            currentItem: {},
            action_loading: false,
            // bids panel
            showBids: false,
            bidsInfo: null,
            // notes panel
            showNotes: false,
            notesOrderid: null,
            // batch panel
            showBatchPanel: false,
            batchPanelId: null,
            batchOrderid: null,
            // testimonials panel
            showTestimonials: false,
            testimonialsOrderid: null,
            // details panel
            showDetails: false,
            showPanel: false,
            detailsOrderid: null,
            // search
            search_fields: null,
            searchOptions: {
                page: 1,
                per_page: 10,
                sw_status: '',
                status: '',
                search_for: '',
                search_by: 'orderid',
                sort_by: 'created_at',
                sort_order: 'DESC',
                domain: '',
                from: '',
                to: '',
                preset: ''
            },
            // helpers
            isFirstContentLoaded: false
        }
    },
    metaInfo() {
        return {
            title: `Orders ${this.searchOptions.preset}`
        }
    },
    computed: {
        ...mapGetters('orders', [
            'getterOrderList',
            'getterLastPage',
            'getterTotal',
            'getterCurrentPage',
            'getterOrderListLoading',
            'getterTotalsLoading',
            'getterPresetsLoading',
            'getterPresets'
        ]),
        toolbars() {
            return MASS_ORDER_TOOLBARS
        },
        modalComponent() {
            return this.modal.name || ''
        },
        queryObject() {
            const { query } = this.$route

            return {
                ...query,
                page: +this.$route.query.page || 1,
                per_page: +this.$route.query.per_page || 10
            }
        },
        allowedFileTypes() {
            return ALLOWED_FILE_TYPES
        },
        focusedOrderid() {
            return this.detailsOrderid || this.testimonialsOrderid || this.notesOrderid || this.bidsInfo?.orderid || this.batchOrderid
        }
    },
    watch: {
        '$route.query': async function (newValue, oldVal) {
            if (newValue.preset !== oldVal.preset) {
                await this.onFilterTable(newValue)
            }
        },
        showPanel(val) {
            Helper.lockHtml(val)
        }
    },
    async activated() {
        this.$nextTick(() => {
            window.scroll(0, this.savedScroll.y);
        })
        this.searchOptions = {
            ...this.searchOptions,
            ...this.queryObject
        }
        const requests = [this.get_search_fields()]
        if (!this.isFirstLoading) {
            requests.push(this.getOrderList())
        }
        await Promise.allSettled(requests)
        this.timerID = setTimeout(async () => {
            await this[ACTION_ORDER_LIST_LIVE_UPDATE]({ ...this.searchOptions })
        }, 1000 * 30);
    },
    deactivated() {
        clearTimeout(this.timerID)
    },
    beforeRouteEnter(to, from, next) {
        if (!from.fullPath.includes('/orders')) {
            next((vm) => {
                vm[CLEAR_ORDER_LIST]()
            })
        } else {
            next()
        }
    },
    beforeRouteLeave(to, from, next) {
        const scrollOffset = { y: window.pageYOffset || document.documentElement.scrollTop, x: 0 }
        this.savedScroll = { ...scrollOffset }
        next()
    },
    methods: {
        async get_search_fields() {
            this.search_fields = await searchFieldsHelper.getSearchField('order')
        },
        ...mapAdminActions({
            ACTION_GET_ORDER_LIST,
            ACTION_GET_ORDER_TOTALS,
            ACTION_UPLOAD_ORDER_FILE,
            ACTION_GET_ORDER_PRESETS,
            ACTION_ASIGN_WRITER,
            ACTION_ORDER_FEE,
            ACTION_ORDER_DEADLINE,
            ACTION_ORDER_DROP,
            ACTION_ORDER_REVISION,
            ACTION_ORDER_LIST_LIVE_UPDATE,
            ACTION_CREATE_NOTE,
            ACTION_NOTIFY_COMPLETE_ORDER,
            ACTION_NOTIFY_UNPAID_ORDER,
            ACTION_ORDER_CHANGE_STATUS
        }),
        ...mapOrderMutations({
            DELETE_BID,
            ASSIGN_BID,
            DELETE_NOTE_COUNTER,
            CLEAR_ORDER_LIST
        }),
        ...mapBookmarkActions({
            ACTION_CREATE_BOOKMARK
        }),
        ...mapSupportActions({
            ACTION_CREATE_TICKET_WRITER,
            ACTION_CREATE_TICKET
        }),
        onToolbarActivated(payload) {
            this.actionName = payload.type
            this.modal = payload.modal
        },
        async getOrderList(with_total = true) {
            if (this.$route.name !== 'orders') return
            try {
                if (JSON.stringify(this.searchOptions) !== JSON.stringify(this.queryObject)) {
                    this.$router.replace({ query: { ...this.searchOptions } })
                }
                const requestArr = [this[ACTION_GET_ORDER_LIST]({ ...this.searchOptions, mode: 'list' })]
                if (with_total) {
                    requestArr.push([this[ACTION_GET_ORDER_PRESETS]()])
                    requestArr.push(this[ACTION_GET_ORDER_TOTALS]({ ...this.searchOptions, mode: 'count' }))
                }
                await Promise.all(requestArr)
                this.isFirstLoading = false
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        async onFilterTable(payload) {
            if (this.isFirstContentLoaded === false) {
                this.searchOptions = { ...this.searchOptions, ...payload }
            } else {
                this.searchOptions = { ...this.searchOptions, ...payload, page: 1 }
            }
            // await this[CLEAR_ORDER_LIST]()
            await this.getOrderList()
            this.isFirstContentLoaded = true
        },
        async onPaginate(page) {
            this.searchOptions.page = page
            window.scrollTo(0, 0);
            await this.getOrderList(false)
        },
        async onSortTable(option) {
            this.searchOptions = {
                ...this.searchOptions,
                ...option,
                page: 1
            }
            await this.getOrderList()
        },
        onSelected(payload) {
            this.selected = payload
        },
        updateShortcat() {
            this.searchOptions.page = 1
        },
        // MODALS LOGIC
        async onConfirmModal(payload) {
            this.action_loading = true
            try {
                switch (this.actionName) {
                case 'upload_files':
                    await this.uploadFiles(payload)
                    break

                case 'bookmark':
                    await this.createBookmark(payload)
                    break

                case 'bookmark_mass':
                    await this.createBookmarkMass(payload)
                    break

                case 'rate_complete':
                    await this.notifyCompleteOrder(payload)
                    break

                case 'notify_unpaid':
                    await this.notifyUnpaidOrder(payload)
                    break

                case 'assign':
                    await this.assignWriter(payload)
                    break

                case 'Fee':
                    await this.orderFee(payload)
                    break

                case 'deadline':
                    await this.orderDeadline(payload)
                    break

                case 'client':
                    await this.createClientTicket(payload)
                    break

                case 'message':
                    await this.createWriterTicket(payload)
                    break

                case 'mass_assign':
                    await this.assignWritersMass(payload)
                    break

                case 'drop':
                    await this.orderWriterDrop(payload)
                    break
                case 'revision':
                    await this.orderCreateRevision(payload)
                    break

                case 'notes':
                    await this.orderCreateNote(payload)
                    break

                case 'change_status':
                    await this.orderChangeStatus()
                    break

                default:
                    return
                }
                eventBus.$emit('showSnackBar', this.modal.success_message, 'success')
                this.onCloseModal()
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.action_loading = false
            }
        },
        async orderWriterDrop(payload) {
            const { orderid } = this.currentItem
            await this[ACTION_ORDER_DROP]({ orderid, details: payload.reason })
        },
        async orderCreateRevision(payload) {
            const { orderid } = this.currentItem
            await this[ACTION_ORDER_REVISION]({ orderid, ...payload })
        },
        onCloseModal() {
            this.modal = {}
            this.currentItem = {}
            this.actionName = ''
        },
        onActionRow(item, action) {
            this.actionName = action.actionName
            this.modal = action.modal
            this.currentItem = item
        },
        async createBookmark(payload) {
            await this[ACTION_CREATE_BOOKMARK]({ ...payload, referenceids: [this.currentItem.orderid] })
        },
        async createBookmarkMass(payload) {
            const referenceids = this.selected.map((item) => item.orderid)
            await this[ACTION_CREATE_BOOKMARK]({ ...payload, referenceids })
        },
        async uploadFiles({ formData, callback }) {
            const { orderid } = this.currentItem
            formData.append('orderid', orderid)
            await this[ACTION_UPLOAD_ORDER_FILE]({
                formData, callback
            })
        },
        async assignWriter(payload) {
            const { orderid } = this.currentItem
            const writer_data = {
                writer_id: payload.sw_id,
                order_id: orderid
            }
            await this[ACTION_ASIGN_WRITER]({ ...writer_data })
        },
        async assignWritersMass(payload) {
            const writer_data = {
                writer_firstname: payload.firstname,
                writer_lastname: payload.lastname,
                writer_field_id: payload.sw_id,
                writer_email: payload.email,
                writer_avatar: payload.avatar,
                sw_id: payload.sw_id
            }
            const orderIds = this.selected.map((item) => item.orderid)
            await this[ACTION_ASIGN_WRITER]({ sw_id: payload.sw_id, orders: [...orderIds], ...writer_data })
        },
        async orderFee(payload) {
            const { orderid } = this.currentItem
            await this[ACTION_ORDER_FEE]({ ...payload, orderid })
        },
        async orderDeadline(payload) {
            const { orderid } = this.currentItem
            await this[ACTION_ORDER_DEADLINE]({ ...payload, orderid })
        },
        async createClientTicket(payload) {
            const { orderid, clientid } = this.currentItem
            await this[ACTION_CREATE_TICKET]({
                clientid,
                orderid,
                status_priority: 'NORMAL',
                subject: 'inquiry',
                msgtype: 'inquiry',
                msgcategory: '',
                msgresolution: '',
                ...payload
            })
        },
        async createWriterTicket(payload) {
            const { orderid, sw_id } = this.currentItem
            await this[ACTION_CREATE_TICKET_WRITER]({
                clientid: sw_id,
                orderid,
                status_priority: 'NORMAL',
                subject: 'inquiry',
                msgtype: 'inquiry',
                ...payload
            })
        },
        async orderCreateNote(payload) {
            const { orderid } = this.currentItem
            await this[ACTION_CREATE_NOTE]({ orderid, ...payload })
        },
        async notifyCompleteOrder() {
            const referenceids = this.selected.map((item) => item.orderid)
            const promises = referenceids.map((item) => this[ACTION_NOTIFY_COMPLETE_ORDER]({ orderid: item }))
            await Promise.all(promises)
        },
        async notifyUnpaidOrder() {
            const referenceids = this.selected.map((item) => item.orderid)
            const promises = referenceids.map((item) => this[ACTION_NOTIFY_UNPAID_ORDER]({ orderid: item }))
            await Promise.all(promises)
        },
        async orderChangeStatus() {
            const { orderid } = this.currentItem
            await this[ACTION_ORDER_CHANGE_STATUS]({ order_id: orderid })
        },
        // BIDS
        onShowBids(info) {
            this.bidsInfo = info
            this.showBids = true
            this.showPanel = true
        },
        onCloseBids() {
            this.showBids = false
            this.showPanel = false
            this.bidsInfo = null
            this.orderDue = null
        },
        onDeleteBid(payload) {
            this[DELETE_BID](payload)
        },
        onAssignBid(bid, orderid) {
            this[ASSIGN_BID]({ ...bid, orderid })
        },

        // DETAILS

        onShowDetails(orderid) {
            this.showDetails = true
            this.showPanel = true
            this.detailsOrderid = orderid
        },

        onCloseDetails() {
            this.showDetails = false
            this.showPanel = false
            this.detailsOrderid = null
        },

        // NOTES
        onShowNotes(orderid) {
            this.showNotes = true
            this.showPanel = true
            this.notesOrderid = orderid
        },
        onShowTestimonials(orderid) {
            this.showTestimonials = true
            this.showPanel = true
            this.testimonialsOrderid = orderid
        },
        onCloseTestimonials() {
            this.showTestimonials = false
            this.showPanel = false
            this.testimonialsOrderid = null
        },
        onCloseNotes() {
            this.showNotes = false
            this.showPanel = false
            this.notesOrderid = null
        },
        onDeleteNote(orderid) {
            this[DELETE_NOTE_COUNTER](orderid)
        },

        // Batches
        onShowBatches(batchid, orderid) {
            this.showBatchPanel = true
            this.batchPanelId = batchid
            this.batchOrderid = orderid
        },
        onCloseBatches() {
            this.showBatchPanel = false
            this.batchPanelId = null
            this.batchOrderid = null
        }
    }
}
</script>

<style lang="scss">
    .order_table__total{
        word-wrap: break-word;
    }
    .loader{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0,0,0,0.3);
        z-index: 999;
    }
</style>
