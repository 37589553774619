<template>
    <v-row>
        <v-col cols="6">
            <v-card
                class="py-5 px-5"
            >
                <div class="d-flex justify-space-between align-center">
                    <p class="font-weight-bold text-subtitle-1 mb-0">
                        Choose the options to create an address book
                    </p>
                </div>

                <the-emails-filter
                    :total-clients="totalClients"
                    @saveFilter="onSaveFilter"
                />
            </v-card>
        </v-col>
        <v-col cols="6">
            <v-card
                class="py-5 clients-view"
            >
                <div class="d-flex justify-space-between align-center px-5">
                    <p class="font-weight-bold text-subtitle-1 mb-0">
                        Recipients preview
                    </p>
                </div>
                <div class="d-flex my-5 py-3 px-5 blue-grey lighten-5">
                    <div class="mr-4">
                        <p
                            v-if="totalClients || totalClients === 0"
                            class="mb-1"
                        >
                            <b>Emails: </b>~{{ totalClients }}
                        </p>
                        <p class="mb-1">
                            <b>Domain:</b> {{ search.domain }}
                        </p>
                    </div>
                    <div class="">
                        <p
                            v-if="search.from"
                            class="mb-1"
                        >
                            <b>Period (from):</b> {{ search.from }}
                        </p>
                        <p
                            v-if="search.to"
                            class="mb-1"
                        >
                            <b>Period (to):</b> {{ search.to }}
                        </p>
                    </div>
                </div>
                <div
                    v-if="clients.length"
                    class="bids_scroll px-5"
                >
                    <div
                        v-for="(item,index) in clients"
                        :key="index"
                        class="my-4"
                    >
                        <div class="">
                            <div class="d-flex">
                                <div class="mr-5">
                                    <p class="grey--text mb-0">
                                        Created
                                    </p>
                                    <p class="font-weight-medium">
                                        {{ $date.humanizeDate(item.created_at) }}
                                    </p>
                                </div>
                                <div class="mr-5">
                                    <p class="grey--text mb-0">
                                        Recipient Email
                                    </p>
                                    <p class="font-weight-medium">
                                        {{ item.email }}
                                    </p>
                                </div>
                            </div>

                            <v-divider v-if="index !== clients.length - 1" />
                        </div>
                    </div>
                    <div
                        v-if="!clients.length && !clientsLoading"
                        class="d-flex flex-column align-center w-full mt-5 mb-5"
                    >
                        <div class="">
                            <img
                                src="@images/svg/no-data.svg"
                                alt=""
                            >
                        </div>
                        <p class="text-h6">
                            No recipients found
                        </p>
                    </div>
                    <div
                        v-if="clientsPage < clientsLastPage"
                        class="d-flex justify-center"
                    >
                        <v-progress-circular
                            ref="loader"
                            :size="50"
                            :width="4"
                            color="primary"
                            indeterminate
                        />
                    </div>
                </div>
                <div
                    v-else
                    class=""
                >
                    <div
                        class="d-flex flex-column align-center w-full mt-5 mb-5"
                    >
                        <div class="">
                            <img
                                src="@images/svg/no-data.svg"
                                alt=""
                            >
                        </div>
                        <p class="text-h6 text-center">
                            No recipients found <br>
                            Please fill out the form on the left
                        </p>
                    </div>
                </div>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import TheEmailsFilter from '@/components/Emails/TheEmailsFilter'

import Api from '@/helpers/api/index.js'
import { eventBus } from '@/helpers/event-bus/'
import { removeEmptyParamsFromRequest } from '@/helpers/utils/index.js'
import { mapEmailBuilderFormFields } from '@/store/modules/emailBuilder/index.js'

export default {
    components: {
        TheEmailsFilter
    },
    data() {
        return {
            clients: [],
            totalClients: 0,
            clientsLoading: true,
            clientsPage: 1,
            clientsLastPage: Infinity
        }
    },
    computed: {
        ...mapEmailBuilderFormFields({
            search: 'form_data.search'
        })
    },
    async created() {
        if (this.search.domain) {
            await this.onSaveFilter()
        }
        eventBus.$on('resetEmailBuilder', () => {
            this.clients = []
            this.totalClients = 0
            this.clientsLoading = true
            this.clientsPage = 1
            this.clientsLastPage = Infinity
        })
    },
    methods: {
        async onSaveFilter(clearClients = true) {
            if (clearClients) {
                this.clients = []
                this.clientsPage = 1
            }
            try {
                this.clientsLoading = true
                const { data } = await Api.get('/api/client/list', { ...removeEmptyParamsFromRequest(this.search), page: this.clientsPage })
                this.totalClients = data.clients.total
                this.clients = [...this.clients, ...data.clients.data]
                this.clientsLastPage = data.clients.last_page
                this.$nextTick(() => {
                    if (this.clientsPage < this.clientsLastPage) {
                        this.infinityScroll()
                    }
                })
                this.$emit('saveAddressBook', { ...this.search })
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.clientsLoading = false
            }
        },
        infinityScroll() {
            const { loader } = this.$refs;
            const vm = this
            const options = {
                root: null,
                rootMargin: '0px',
                threshold: 0.25
            };
            function handleIntersect(entries) {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        if ((vm.clientsPage < vm.clientsLastPage) && !vm.clientsLoading) {
                            vm.clientsPage += 1
                            vm.onSaveFilter(false)
                        }
                    }
                });
            }
            const observer = new IntersectionObserver(handleIntersect, options);
            observer.observe(loader.$el);
        }
    }
}
</script>

<style lang="scss">
    .clients-view{
        .bids_scroll{
            height: calc(100vh - 440px) !important;
        }
    }
</style>
