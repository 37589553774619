var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[[_c('select-tabs',{attrs:{"items":_vm.tabs},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}}),_c('KeepAlive',{attrs:{"max":3}},[_c('router-view',{key:_vm.$route.fullPath,staticClass:"white",attrs:{"orderid":_vm.orderId,"order":_vm.order,"client":{
                        id:_vm.order.clientid,
                        ordersCount:_vm.order.client_orders_count,
                        clientCountry:_vm.order.client_country,
                        firstname:_vm.order.client_firstname,
                        lastname:_vm.order.client_lastname,
                        avatar:_vm.order.client_avatar
                    }},on:{"createTicket":_vm.onCreateTicket,"update:counters":_vm.updateCounters,"uploadMainFiles":_vm.uploadMainFiles}})],1)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }