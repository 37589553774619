/* eslint-disable class-methods-use-this */
import Api from '@/helpers/api/index.js'
import { removeEmptyParamsFromRequest } from '@/helpers/utils/index.js'

class ProjectTypesService {
    async getList(payload) {
        if (payload.search_by && !payload.search_for.length) {
            delete payload.search_by;
        }
        const { data } = await Api.get('/api/project-type/list', removeEmptyParamsFromRequest({ ...payload }), {}, false);
        return data
    }

    async createType(payload) {
        const { data } = await Api.post('/api/project-type/create', { ...payload });
        return data
    }

    async activateType(payload) {
        const { data } = await Api.put('/api/project-type/set-status-active', { ...payload });
        return data
    }

    async deactivateType(payload) {
        const { data } = await Api.put('/api/project-type/set-status-inactive', { ...payload });
        return data
    }

    async updateType(payload) {
        const { data } = await Api.put('/api/project-type/update', { ...payload });
        return data
    }
}

export const projectService = new ProjectTypesService()
