var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{attrs:{"show-modal":_vm.showModal},on:{"closeModal":_vm.onCloseModal,"confirmModal":_vm.onConfirmModal},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',[_vm._v("Create forum")])]},proxy:true},{key:"body",fn:function(){return [_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"outlined":"","color":"accent","label":"Title","rules":_vm.valid_title,"required":""},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c('v-text-field',{attrs:{"outlined":"","color":"accent","label":"Reference ID","rules":_vm.valid_referenceid,"required":""},model:{value:(_vm.referenceid),callback:function ($$v) {_vm.referenceid=$$v},expression:"referenceid"}}),_c('v-select',{attrs:{"items":_vm.priorities,"rules":_vm.valid_status_priority,"label":"Priority","outlined":"","required":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [(item.value !== 'all')?_c('img',{staticClass:"mr-2",attrs:{"src":require(("@images/svg/" + (_vm.getIcon(item.value)))),"alt":item.value}}):_vm._e(),_vm._v(" "+_vm._s(item.text)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [(item.value !== 'all')?_c('img',{staticClass:"mr-2",attrs:{"src":require(("@images/svg/" + (_vm.getIcon(item.value)))),"alt":item.value}}):_vm._e(),_vm._v(" "+_vm._s(item.text)+" ")]}}]),model:{value:(_vm.status_priority),callback:function ($$v) {_vm.status_priority=$$v},expression:"status_priority"}}),_c('v-select',{attrs:{"items":_vm.getterAdminsList,"item-value":"id","outlined":"","menu-props":{ bottom: true, offsetY: true },"label":"Allowed IDs","multiple":"","required":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex my-2 mx-2"},[_c('avatar-rolles',{attrs:{"access-status":"admin","firstname":item.firstname,"lastname":item.lastname,"avatar-url":item.avatar_url,"avatar-fallback":item.avatar_fallback,"avatar-size":"35"}}),_c('div',{staticClass:"ml-4"},[_c('p',{staticClass:"mb-0 text-caption"},[_vm._v(" "+_vm._s(item.firstname)+" "+_vm._s(item.lastname)+" ")]),_c('p',{staticClass:"mb-0 text-caption"},[_vm._v(" Admin ID: "+_vm._s(item.id)+" ")])]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteFromAllowed(item.id)}}},[_c('v-icon',{attrs:{"size":"15","color":"error"}},[_vm._v(" mdi-close ")])],1)],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex my-2"},[_c('avatar-rolles',{attrs:{"access-status":"admin","firstname":item.firstname,"lastname":item.lastname,"avatar-url":item.avatar_url,"avatar-fallback":item.avatar_fallback,"avatar-size":"35"}}),_c('div',{staticClass:"ml-4"},[_c('p',{staticClass:"mb-0 text-caption"},[_vm._v(" "+_vm._s(item.firstname)+" "+_vm._s(item.lastname)+" ")]),_c('p',{staticClass:"mb-0 text-caption"},[_vm._v(" Admin ID: "+_vm._s(item.id)+" ")])])],1)]}}]),model:{value:(_vm.allowed_ids),callback:function ($$v) {_vm.allowed_ids=$$v},expression:"allowed_ids"}})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }