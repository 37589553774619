<template>
    <Viewer
        :title="'Email preview'"
        @closeSidePanel="closeEmail"
    >
        <template #body>
            <div v-html="preview" />
        </template>
    </Viewer>
</template>

<script>
import Viewer from '@/components/SidePanels/Viewer'
import Api from '@/helpers/api/index.js'

export default {
    components: {
        Viewer
    },
    props: {
        email: {
            type: Object,
            require: true
        }
    },
    data() {
        return {
            preview: null
        }
    },
    async created() {
        await this.getEmail()
    },
    methods: {
        closeEmail() {
            this.$emit('closeEmail')
        },
        async getEmail() {
            const { data } = await Api.post('https://mailer.freelancewriting.center/api/mails/preview', {
                mailer: 'mailgun',
                template: 'newsletter-01',
                data: {
                    ...this.email.mail_template_data
                },
                emails: [
                    {
                        email: 'orlando_james2000@yahoo.com',
                        firstname: 'Ruslan',
                        unsubscribe_token: 'test123'
                    }
                ],
                options: {
                    domain: this.email.domain,
                    subject: 'trest'
                }
            })
            this.preview = data
        }
    }
}
</script>

<style lang="scss">
    .email_modal {
        width: 680px !important;
    }
</style>
