<template>
    <Viewer
        :title="'Recipients'"
        @closeSidePanel="closeRecipients"
    >
        <template #body>
            <div
                v-if="recipientsList"
                class="bids_scroll"
            >
                <div
                    v-for="(item,index) in recipientsList"
                    :key="index"
                    class="my-4 "
                >
                    <div class="">
                        <div class="d-flex">
                            <div class="mr-5">
                                <p class="grey--text mb-0">
                                    Created
                                </p>
                                <p class="font-weight-medium">
                                    {{ $date.humanizeDate(item.created_at) }}
                                </p>
                            </div>
                            <div class="mr-5">
                                <p class="grey--text mb-0">
                                    Recipient Email
                                </p>
                                <p class="font-weight-medium">
                                    {{ item.recipient_email }}
                                </p>
                            </div>
                        </div>

                        <v-divider v-if="index !== recipientsList.length - 1" />
                    </div>
                </div>
                <div
                    v-if="!recipientsList.length && !isLoading"
                    class="d-flex flex-column align-center w-full mt-5 mb-5"
                >
                    <div class="">
                        <img
                            src="@images/svg/no-data.svg"
                            alt=""
                        >
                    </div>
                    <p class="text-h6">
                        No recipients found
                    </p>
                </div>
                <div
                    v-if="page < last_page"
                    class="d-flex justify-center"
                >
                    <v-progress-circular
                        ref="loader"
                        :size="50"
                        :width="4"
                        color="primary"
                        indeterminate
                    />
                </div>
            </div>
        </template>
    </Viewer>
</template>

<script>
import Viewer from '@/components/SidePanels/Viewer'

import Api from '@/helpers/api/index.js'
import { removeEmptyParamsFromRequest } from '@/helpers/utils/index.js'

export default {
    components: {
        Viewer
    },
    props: {
        bulkmailId: {
            type: Number,
            require: true
        }
    },
    data() {
        return {
            isLoading: true,
            page: 1,
            last_page: Infinity,
            recipientsList: []
        }
    },
    async created() {
        this.isLoading = true
        await this.getRecipientsList()
        this.isLoading = false
    },
    mounted() {
        this.infinityScroll()
    },
    methods: {
        closeRecipients() {
            this.$emit('closeRecipients')
        },
        async getRecipientsList() {
            const { data } = await Api.get('/api/bulk-mail/list-recipients', removeEmptyParamsFromRequest({ bulkmail_id: this.bulkmailId, page: this.page }))
            this.last_page = data.last_page
            this.recipientsList = [...this.recipientsList, ...data.data]
        },
        infinityScroll() {
            const { loader } = this.$refs;
            const vm = this
            const options = {
                root: null,
                rootMargin: '0px',
                threshold: 0.25
            };
            function handleIntersect(entries) {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        if (vm.page < vm.last_page) {
                            vm.page += 1
                            vm.getRecipientsList()
                        }
                    }
                });
            }
            const observer = new IntersectionObserver(handleIntersect, options);
            observer.observe(loader.$el);
        }
    }
}
</script>

<style lang="scss">
</style>
