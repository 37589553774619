export const EMAILS_TABLE_HEADER = [
    {
        text: 'General',
        value: 'id',
        sortable: true
    },
    {
        text: 'Status',
        value: 'status',
        sortable: true,
        width: '100px'
    },
    {
        text: 'Domain',
        value: 'domain',
        sortable: true,
        width: '100px'
    },
    {
        text: 'Admin',
        value: 'admin_id',
        sortable: true,
        width: '100px'
    },
    {
        text: 'Recipients count',
        value: 'recipients_count',
        sortable: true,
        width: '130px'
    },
    {
        text: 'Created',
        value: 'created_at',
        sortable: true,
        width: '200px'
    },
    {
        text: 'Start',
        value: 'start_at',
        width: '200px',
        sortable: true
    },
    {
        text: 'Preview',
        width: '100px',
        sortable: false
    },
    {
        text: 'Actions',
        width: '150px',
        sortable: false
    }
]

export const EMAILS_TOOLBARS = [
    {
        name: 'send-to-me',
        text: 'Send to me',
        modal: {
            name: 'ModalAlert',
            title: 'Test email sending',
            description: 'Do you want to send the test email?',
            success_message: 'A test email was sent'
        },
        icon: 'mdi-send-lock-outline'
    },
    {
        name: 'send-to-clients',
        text: 'Send to clients',
        modal: {
            name: 'ModalAlert',
            title: 'Sending a bulk of emails',
            description: 'Do you want to send the bulk of emails?',
            success_message: 'A bulk of emails was sent'
        },
        icon: 'mdi-send-outline'
    },
    {
        name: 'edit',
        text: 'Edit',
        modal: {
            name: 'ModalAlert',
            title: 'Edit emailing',
            description: 'Do you want to edit email?'
        },
        icon: 'mdi-send-outline'
    },
    {
        name: 'delete',
        text: 'Delete',
        modal: {
            name: 'ModalAlert',
            title: 'Delete emailing',
            description: 'Do you want to delete email?',
            success_message: 'A bulk of emails was deleted'
        },
        icon: 'mdi-close'
    }
]
