<template>
    <v-row>
        <v-col cols="6">
            <v-card
                class="py-5 px-5"
            >
                <p class="font-weight-bold text-subtitle-1 mb-5">
                    Template
                </p>
                <the-email-form @submitEmailForm="onSubmitEmailForm" />
            </v-card>
        </v-col>
        <v-col cols="6">
            <v-card
                class="py-5 px-5"
                height="100%"
            >
                <div
                    v-if="!preview"
                    class="empty-data"
                >
                    Please complete sampling form
                </div>
                <div v-html="preview" />
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import TheEmailForm from '@/components/Emails/TheEmailForm'

import Api from '@/helpers/api/index.js'
import { mapEmailBuilderFormFields } from '@/store/modules/emailBuilder/'
import { eventBus } from '@/helpers/event-bus/'

export default {
    components: {
        TheEmailForm
    },
    data() {
        return {
            preview: null
        }
    },
    computed: {
        ...mapEmailBuilderFormFields([
            'form_data'
        ])
    },
    async created() {
        if (this.form_data['section-01']) {
            await this.getPreview()
        }
        eventBus.$on('resetEmailBuilder', () => {
            this.preview = null
        })
    },
    methods: {
        async onSubmitEmailForm() {
            await this.getPreview()
            this.$emit('saveEmailPreviw', { ...this.emailData })
        },
        async getPreview() {
            const { data } = await Api.post('https://mailer.freelancewriting.center/api/mails/preview', {
                mail_template: this.form_data.mail_template,
                domain: this.form_data.search.domain,
                mail_template_data: { ...this.form_data, firstname: 'User' }
            })
            this.preview = data
        }
    }
}
</script>

<style>

</style>
