<template>
    <div class="wrapper">
        <v-row class="white py-5">
            <v-col class="d-flex align-center justify-space-between">
                <div class="d-flex align-center">
                    <div class="d-flex flex-column px-2 white">
                        <v-icon
                            size="35"
                            class="mb-2"
                            :color="currentStep === 'address_book' ? 'primary' : ''"
                        >
                            mdi-book-account-outline
                        </v-icon>
                        <span :class="{'font-weight-bold': currentStep === 'address_book', 'primary--text': currentStep === 'address_book'}">
                            Address book
                        </span>
                    </div>
                    <div style="width: 100px">
                        <v-divider :class="{'primary': currentStep === 'email_template'}" />
                    </div>
                    <div class="d-flex flex-column px-2 white">
                        <v-icon
                            size="35"
                            class="mb-2"
                            :color="currentStep === 'email_template' ? 'primary' : ''"
                        >
                            mdi-email-edit-outline
                        </v-icon>
                        <span :class="{'font-weight-bold': currentStep === 'email_template', 'primary--text': currentStep === 'email_template'}">
                            Email template
                        </span>
                    </div>
                </div>
                <div class="d-flex">
                    <v-btn
                        v-if="currentStep === 'email_template'"
                        class="mr-4"
                        @click="goToPrevStep()"
                    >
                        <v-icon class="mr-2">
                            mdi-chevron-left
                        </v-icon>
                        Prev
                    </v-btn>
                    <v-btn
                        v-if="currentStep === 'address_book'"
                        @click="goToNextStep()"
                    >
                        <v-icon class="mr-2">
                            mdi-chevron-right
                        </v-icon>
                        Next
                    </v-btn>
                    <v-btn
                        v-if="currentStep === 'email_template'"
                        color="primary"
                        @click="saveEmail"
                    >
                        <v-icon class="mr-2">
                            mdi-content-save-all-outline
                        </v-icon>
                        Save
                    </v-btn>
                    <v-btn
                        color="error"
                        class="mx-4"
                        @click="clearForm"
                    >
                        <v-icon class="mr-2">
                            mdi-autorenew
                        </v-icon>
                        Reset
                    </v-btn>
                </div>
            </v-col>
        </v-row>
        <v-container
            v-if="!isLoading"
            fluid
            class="my-5"
        >
            <EmailsAddressBook
                v-show="currentStep === 'address_book'"
                @saveAddressBook="onSaveAddressBook"
            />
            <EmailsTemplateCreate
                v-show="currentStep === 'email_template'"
                @saveEmailPreviw="onSaveEmailPreviw"
            />
        </v-container>
    </div>
</template>

<script>
import dayjs from 'dayjs'
import { createNamespacedHelpers } from 'vuex';
import EmailsAddressBook from '@/components/Emails/EmailsAddressBook'
import EmailsTemplateCreate from '@/components/Emails/EmailsTemplateCreate'
import { eventBus } from '@/helpers/event-bus/'

import Api from '@/helpers/api/index.js'
import { mapEmailBuilderFormFields } from '@/store/modules/emailBuilder/'

import {
    RESET_FORM
} from '@/store/modules/emailBuilder/mutation-types';

import {
    ACTION_EDIT_EMAIL
} from '@/store/modules/emailBuilder/action-types';

const {
    mapMutations
} = createNamespacedHelpers('emailBuilder');
const utc = require('dayjs/plugin/utc')

dayjs.extend(utc)

const {
    mapActions: mapEmailBuilderActions
} = createNamespacedHelpers('emailBuilder');

export default {
    components: {
        EmailsAddressBook,
        EmailsTemplateCreate
    },
    data() {
        return {
            isLoading: true
        }
    },
    computed: {
        ...mapEmailBuilderFormFields([
            'activeNextBtn',
            'activeSaveBtn',
            'currentStep',
            'isEdit',
            'form_data'
        ])
    },
    async created() {
        this.activeNextBtn = false
        this.activeSaveBtn = false
        this.isLoading = true
        await this.fetchEmail()
        this.isLoading = false
        this.isEdit = true
    },
    beforeDestroy() {
        this[RESET_FORM]()
    },
    methods: {
        ...mapMutations({
            RESET_FORM
        }),
        ...mapEmailBuilderActions({
            ACTION_EDIT_EMAIL
        }),
        onSaveAddressBook() {
            this.activeNextBtn = true
        },
        onSaveEmailPreviw() {
            this.activeSaveBtn = true
        },
        goToNextStep() {
            if (!this.activeNextBtn) {
                eventBus.$emit('showSnackBar', 'Please fill out the search form and click \'Save\'', 'error')
                return
            }
            this.currentStep = 'email_template'
        },
        async fetchEmail() {
            const { data } = await Api.get('/api/bulk-mail/fetch', { bulkmail_id: this.$route.params.id })
            await this[ACTION_EDIT_EMAIL](data)
        },
        async saveEmail() {
            if (!this.activeSaveBtn) {
                eventBus.$emit('showSnackBar', 'Please fill out the email preview form and click \'Save\'', 'error')
                return
            }
            try {
                await this.saveEmailToList()
                eventBus.$emit('showSnackBar', 'Newsletter saved', 'success')
                this.$router.push({ name: 'emails-list' })
                this.clearForm()
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        goToPrevStep() {
            this.currentStep = 'address_book'
        },
        async saveEmailToList() {
            await Api.post('/api/bulk-mail/save', {
                ...this.form_data.search,
                bulkmail_id: this.$route.params.id,
                mail_template: this.form_data.mail_template,
                mail_subject: this.form_data.mail_subject,
                mail_start_at: dayjs(this.form_data.mail_start_at).utc().format('YYYY-MM-DD HH:mm:ss'),

                mail_template_data: {
                    firstname: 'User',
                    'header-01': this.form_data['header-01'],
                    'section-01': this.form_data['section-01'],
                    button: {
                        text: this.form_data.button.text,
                        url: this.form_data.button.url
                    },
                    subcopy: this.form_data.subcopy
                }
            })
        },
        clearForm() {
            this[RESET_FORM]()
        }
    }
}
</script>

<style lang="scss">
    .empty-data{
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: rgba(0,0,0,0.6);
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-weight: bold;
        font-size: 22px;
    }
</style>
