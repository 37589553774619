<template>
    <div class="">
        <v-row class="my-0">
            <v-col>
                <date-picker
                    :search-options="search"
                    @updatePeriod="updatePeriod"
                />
            </v-col>
        </v-row>

        <!-- DOMAIN -->
        <v-row class="my-0">
            <v-col
                cols="12"
            >
                <v-select
                    v-model="search.domain"
                    :items="getterDomains"
                    label="Domain"
                    outlined
                    hide-details
                />
            </v-col>
        </v-row>

        <!-- Button -->
        <v-row class="my-0">
            <v-col class="d-flex align-center">
                <v-btn
                    color="primary"
                    x-large
                    @click="saveFilter"
                >
                    Save
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex';

import DatePicker from '@/components/shared/DatePicker'
import { mapEmailBuilderFormFields } from '@/store/modules/emailBuilder/index.js'

import {
    ACTION_GET_DOMAINS
} from '@/store/modules/config/action-types';

const {
    mapActions: mapAdminActions
} = createNamespacedHelpers('config');

export default {
    components: {
        DatePicker
    },
    props: {
        totalClients: {
            type: Number,
            required: false
        }
    },
    computed: {
        ...mapGetters('config', [
            'getterDomains'
        ]),
        ...mapEmailBuilderFormFields({
            search: 'form_data.search'
        })
    },
    async created() {
        await this[ACTION_GET_DOMAINS]()
    },
    methods: {
        ...mapAdminActions({
            ACTION_GET_DOMAINS
        }),
        updatePeriod(payload) {
            this.search.from = payload.from
            this.search.to = payload.to
        },
        saveFilter() {
            this.$emit('saveFilter', this.search)
        }
    }
}
</script>

<style>

</style>
