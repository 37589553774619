<template>
    <p>
        GATEWAY
    </p>
</template>

<script>
export default {

}
</script>

<style>

</style>
