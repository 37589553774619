<template>
    <v-container
        fluid
        class="white rounded"
    >
        <div>
            <div class="tw-bg-yellow-100 tw-w-full pa-3">
                <div class="order-details-top-main-info">
                    <div class="order-details-top-main-info__title">
                        <table-rows-menu
                            :toolbars="toolbars"
                            :item="info"
                            class="mt-0 d-flex align-center"
                            @menuActivated="onMenuActivated"
                        />
                        <div class="d-flex flex-column">
                            <div
                                class="d-flex"
                                style="gap: 5px"
                            >
                                <domain-favicon
                                    :domain="info.client.domain"
                                />
                                <span class="tw-text-2xl tw-font-bold tw-rounded">
                                    {{ info.project_information.type }}
                                </span>
                            </div>
                            <span class="tw-text-2xl tw-italic">
                                &laquo;{{ info.project_information.title }}&raquo;
                            </span>
                        </div>
                    </div>
                    <div class="order-details-top-main-info__data">
                        <!-- Discount code-->
                        <div
                            v-if="info.discount_code"
                            class=""
                        >
                            <p class="grey--text mb-0">
                                Discount code
                            </p>
                            <p
                                class="mb-0 d-flex align-center tw-text-base tw-font-bold"
                            >
                                <span style="white-space: nowrap">{{ info.discount_code }}</span>
                            </p>
                        </div>
                        <!-- Discount-->
                        <div
                            v-if="info.discount !== '0.00'"
                            class=""
                        >
                            <p class="grey--text mb-0">
                                Discount
                            </p>
                            <p
                                class="mb-0 d-flex align-center tw-text-base tw-font-bold"
                            >
                                <span style="white-space: nowrap">{{ info.discount | money }}</span>
                            </p>
                        </div>
                        <!-- Linked to order -->
                        <div
                            v-if="info.linked_to_order"
                            class=""
                        >
                            <p class="grey--text mb-0">
                                Linked to order:
                            </p>
                            <p
                                class="mb-0 d-flex align-center tw-text-base tw-font-bold"
                            >
                                <span style="white-space: nowrap">{{ info.linked_to_order }}</span>
                                <copy-to-click-board
                                    class="d-inline-flex ml-2"
                                    :copy-string="info.linked_to_order"
                                    icon
                                />
                            </p>
                        </div>
                        <div>
                            <p class="grey--text mb-0">
                                Order ID:
                            </p>
                            <p
                                v-if="info.orderid"
                                class="mb-0 d-flex align-center tw-text-base tw-font-bold"
                            >
                                <span style="white-space: nowrap">{{ info.orderid }}</span>
                                <copy-to-click-board
                                    class="d-inline-flex ml-2"
                                    :copy-string="info.orderid"
                                    icon
                                />
                            </p>
                        </div>
                        <div
                            v-if="info.batch"
                            class="mt-0"
                        >
                            <p class="grey--text mb-0">
                                Payment ID:
                            </p>
                            <p
                                class="mb-0 d-flex align-center tw-text-base tw-font-bold"
                            >
                                <span style="white-space: nowrap">{{ info.batch.batchid }}</span>
                                <copy-to-click-board
                                    class="d-inline-flex ml-2"
                                    :copy-string="info.batch.batchid"
                                    icon
                                />
                            </p>
                        </div>
                        <div class="d-flex align-center tw-text-4xl tw-font-bold tw-text-green-800">
                            {{ info.fin.total | money }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="order-details-top-main-extra">
            <div class="order-details-top-main-extra__client">
                <p class="grey--text mb-2">
                    Client:
                </p>
                <div
                    class="d-flex"
                >
                    <div class="avatar_chips">
                        <client-avatar
                            :id="info.client.id"
                            :is-client="true"
                            :orders-count="info.client.statistics.orders_total"
                            :client-country="info.client.country"
                            :firstname="info.client.firstname"
                            :lastname="info.client.lastname"
                            :avatar="info.client.avatar_url"
                        />
                    </div>
                    <div class="ml-8">
                        <p class="mb-0">
                            {{ nameCapitalized(info.client.firstname) }} {{ nameCapitalized(info.client.lastname) }}
                        </p>
                        <p class="mb-0">
                            ID: {{ info.client.id }}
                        </p>
                        <v-tooltip
                            v-if="info.client.utm"
                            top
                            color="primary"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    v-bind="attrs"
                                    class="info-text"
                                    color="primary"
                                    size="40"
                                    v-on="on"
                                >
                                    mdi-advertisements
                                </v-icon>
                            </template>
                            <div class="d-flex flex-column">
                                <div class="mb-4">
                                    <p class="mb-0 text-body-2">
                                        Campaign:
                                    </p>
                                    <p
                                        v-if="info.client.utm.utm_campaign"
                                        class="mb-0 d-flex align-center text-subtitle-2 font-weight-regular"
                                    >
                                        <span>{{ info.client.utm.utm_campaign }}</span>
                                    </p>
                                    <p v-else>
                                        &mdash;
                                    </p>
                                </div>
                                <div class="mb-4">
                                    <p class="mb-0 text-body-2">
                                        Source:
                                    </p>
                                    <p
                                        v-if="info.client.utm.utm_source"
                                        class="mb-0 d-flex align-center text-subtitle-2 font-weight-regular"
                                    >
                                        <span>{{ info.client.utm.utm_source }}</span>
                                    </p>
                                    <p v-else>
                                        &mdash;
                                    </p>
                                </div>
                                <div class="mb-4">
                                    <p class="mb-0 text-body-2">
                                        Medium:
                                    </p>
                                    <p
                                        v-if="info.client.utm.utm_medium"
                                        class="mb-0 d-flex align-center text-subtitle-2 font-weight-regular"
                                    >
                                        <span>{{ info.client.utm.utm_medium }}</span>
                                    </p>
                                    <p v-else>
                                        &mdash;
                                    </p>
                                </div>
                            </div>
                        </v-tooltip>
                    </div>
                </div>
            </div>
            <!-- Statuses -->
            <div
                v-if="info.status || info.sw_status"
                class=" order-details-top-main-extra__status"
            >
                <div class="d-flex flex-column w-full">
                    <!-- Client status -->
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-chip
                                :color="statusBgColor(info.status)"
                                :text-color="statusTextColor(info.status)"
                                v-bind="attrs"
                                class="mb-2 d-flex justify-center"
                                v-on="on"
                            >
                                {{ info.status }}
                            </v-chip>
                        </template>
                        <span>
                            Client status
                        </span>
                    </v-tooltip>
                    <!-- Writer status -->
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-chip
                                :color="statusBgColor(info.sw_status)"
                                :text-color="statusTextColor(info.sw_status)"
                                v-bind="attrs"
                                class="d-flex justify-center"
                                v-on="on"
                            >
                                {{ info.sw_status }}
                            </v-chip>
                        </template>
                        <span>
                            Writer status
                        </span>
                    </v-tooltip>
                </div>
            </div>
            <div class="order-details-top-main-extra__writer">
                <div>
                    <p class="grey--text mb-2">
                        Writer:
                    </p>
                    <div
                        v-if="info.writer"
                        class="d-flex"
                    >
                        <div class="relative">
                            <writer-avatar
                                :id="info.writer.id"
                                :feedbacks="info.writer.statistics.rank_all_count"
                                :rating="info.writer.statistics.rank_all"
                                :rating_recent="info.writer.statistics.rank_thirty_days"
                                :applied="info.writer.created_at"
                                :status="info.writer.trust_status"
                                :firstname="info.writer.firstname"
                                :lastname="info.writer.lastname"
                                :avatar-url="info.writer.avatar_url"
                                :order_limit="info.writer.projects_limit"
                                :country="info.writer.country"
                                :country-nice-name="info.writer.country_nice_name"
                                :bottom="true"
                            />
                        </div>
                        <div class="ml-5">
                            <p class="mb-0">
                                {{ nameCapitalized(info.writer.firstname) }} {{ nameCapitalized(info.writer.lastname) }}
                            </p>
                            <p class="mb-0">
                                ID: {{ info.writer.id }}
                            </p>
                        </div>
                    </div>
                    <p
                        v-else
                        style="margin: 15px 0"
                    >
                        —
                    </p>
                </div>
                <!-- Bids -->
                <div>
                    <p class="grey--text mb-2">
                        Bids:
                    </p>
                    <v-avatar
                        v-if="info.counters.bids_count > 0"
                        color="rgba(25, 118, 210, 0.1)"
                        size="43"
                        style="cursor: pointer;"
                        @click="showBids(info.orderid, info.due_at)"
                    >
                        <span class="primary--text font-weight-bold">{{ info.counters.bids_count || '0' }}</span>
                    </v-avatar>
                    <p
                        v-else
                        style="margin: 15px 0"
                    >
                        —
                    </p>
                </div>
                <!-- Preferred writer -->
                <div>
                    <p
                        class="grey--text mb-2"
                        style="white-space: nowrap"
                    >
                        Preferred writer:
                    </p>
                    <v-avatar
                        v-if="info.writer_preferrences.preferred !== null"
                        color="#27ae601a"
                        size="43"
                        :style="info.writer_preferrences.preferred.length > 0 ? 'cursor:pointer' : ''"
                        @click.stop="openSidebar('preferred')"
                    >
                        <span
                            class="font-weight-regular text-body-2 success--text"
                        >
                            {{ info.writer_preferrences.preferred.length }}
                        </span>
                    </v-avatar>
                    <p
                        v-else
                        style="margin: 15px 0"
                    >
                        —
                    </p>
                </div>
                <!-- Blocked writer: -->
                <div>
                    <p
                        class="grey--text mb-2"
                        style="white-space: nowrap"
                    >
                        Blocked writer:
                    </p>
                    <v-avatar
                        v-if="info.writer_preferrences.blocked !== null"
                        color="#27ae601a"
                        size="43"
                        :style="info.writer_preferrences.blocked.length > 0 ? 'cursor:pointer' : ''"
                        @click.stop="openSidebar('blocked')"
                    >
                        <span
                            class="font-weight-regular text-body-2 success--text"
                        >
                            {{ info.writer_preferrences.blocked.length }}
                        </span>
                    </v-avatar>
                    <p
                        v-else
                        style="margin: 15px 0"
                    >
                        —
                    </p>
                </div>
            </div>
        </div>
        <prefered-blocked-viewer
            v-if="showWriters && showPanel"
            :items="getterWritersShortList"
            :title="writersTitle"
            @closeWriters="closeWriters"
        />
    </v-container>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex';

import CopyToClickBoard from '@/components/shared/Helpers/CopyToClickBoard'
import WriterAvatar from '@/components/shared/Avatars/WriterAvatar'
import ClientAvatar from '@/components/shared/Avatars/ClientAvatar'
import DomainFavicon from '@/components/shared/Helpers/DomainFavicon'
import TableRowsMenu from '@/components/shared/Tables/TableRowsMenu'
import PreferedBlockedViewer from '@/components/SidePanels/PreferedBlockedViewer';

import filtersMixin from '@/mixins/filtersMixin'

import { nameCapitalized } from '@/helpers/capitalized/index'
import { statusBgColor, statusText, statusTextColor } from '@/helpers/order/statusHelper'
import { ACTION_GET_WRITER_SHORT_LIST } from '@/store/modules/writers/action-types';
import Helper from '@/helpers/functions';

const {
    mapActions: mapWritersActions
} = createNamespacedHelpers('writers');

export default {
    components: {
        WriterAvatar,
        ClientAvatar,
        CopyToClickBoard,
        DomainFavicon,
        TableRowsMenu,
        PreferedBlockedViewer
    },
    mixins: [filtersMixin],
    props: {
        info: {
            type: Object,
            required: true
        },
        toolbars: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            showWriters: false,
            writersTitle: '',
            showPanel: false
        }
    },
    computed: {
        ...mapGetters('writers', [
            'getterWritersShortList'
        ])
    },
    watch: {
        showPanel(val) {
            Helper.lockHtml(val)
        }
    },
    methods: {
        ...mapWritersActions({
            ACTION_GET_WRITER_SHORT_LIST
        }),
        statusText,
        statusBgColor,
        statusTextColor,
        nameCapitalized,
        showBids(orderid, due_at) {
            this.$emit('showBids', orderid, due_at)
        },
        rankBackgroundColor(val) {
            if (val >= 5 && val < 9) {
                return 'rgba(240, 125, 0, 1)'
            } if (val >= 9) {
                return 'rgba(39, 217, 31, 1)'
            }
            return 'rgba(255, 0, 0, 1)'
        },
        onMenuActivated(item, payload) {
            this.$emit('toolbarActivated', payload)
        },
        openSidebar(type) {
            let writers = []
            if (type === 'preferred') {
                writers = this.info.writer_preferrences.preferred
                this.writersTitle = 'Preffered writers'
            }
            if (type === 'blocked') {
                writers = this.info.writer_preferrences.blocked
                this.writersTitle = 'Blocked writers'
            }
            if (writers.length === 0) {
                return
            }
            this.showWriters = true
            this.showPanel = true
            this[ACTION_GET_WRITER_SHORT_LIST]({ writers })
        },
        closeWriters() {
            this.showWriters = false
            this.showPanel = false
        }
    }
}
</script>

<style lang="scss">
    .order-details-top-main-info{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        @media (max-width: 992px) {
            flex-direction: column;
            flex-wrap: wrap;
        }
    }
    .order-details-top-main-info__title{
        display: flex;
        align-items: center;
        @media (max-width: 992px) {
            width: 100%;
        }
    }
    .order-details-top-main-info__data{
        display: flex;
        align-items: center;
        gap: 20px;
        @media (max-width: 992px) {
            flex-wrap: wrap;
            width: 100%;
        }
    }
    .order-details-top-main-extra{
        display: flex;
        align-items: center;
        gap: 30px;
        margin: 8px 0;
        flex-wrap: wrap;
        &__client {
            @media (max-width: 992px) {
                width: 45%;
            }
        }
        &__status {
            display: flex;
            align-items: center;
            width: 250px;
            @media (max-width: 992px) {
                width: 45%;
            }
        }
        &__writer {
            display: flex;
            align-items: center;
            gap: 30px;
            flex-wrap: wrap;
            @media (max-width: 992px) {
              width: 100%;
            }
        }
    }
    .avatar_chips {
        position: relative;
        &__value{
            width: 20px;
            height: 20px;
            position: absolute;
            top: 0;
            right: -5px;
            background: rgb(39, 217, 31);
            color: #fff;
            font-weight: bold;
            text-align: center;
            line-height: 20px;
            border-radius: 50%;
            font-size: 12px;
        }
    }
    .relative{
        position: relative;
    }
    .avatar_rank--check{
        position: absolute !important;
        top: 25px;
        right: -5px;
        width: 17px;
        height: 17px;
        background: rgba(89, 199, 77, 0.1);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .card-title-overflow{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }
</style>
