<template>
    <div class="wrapper px-5 py-5 writers-page">
        <v-container
            fluid
            class="white rounded"
        >
            <the-project-types-filter
                :search-options="searchOptions"
                @filterTable="onFilterTable"
                @createForumClick="onCreateType"
            />

            <project-types-table
                :table-items="types"
                :loading="isLoading"
                :per_page="searchOptions.per_page"
                @actionRow="onActionRow"
                @sortTable="onSortTable"
            />

            <component
                :is="modalComponent"
                :title="modal.title"
                :description="modal.description"
                :input-label="modal.inputLabel"
                :current="currentItem"
                @closeModal="onCloseModal"
                @confirmModal="onConfirmModal"
            />
        </v-container>
    </div>
</template>

<script>
import { debounce } from 'lodash'
import { projectService } from '@/services/projectTypesService'
import { eventBus } from '@/helpers/event-bus/'

import TheProjectTypesFilter from '@/components/shared/TableFilters/TheProjectTypesFilter'
import ProjectTypesTable from '@/components/ProjectTypes/ProjectTypesTable'
import CreateForum from '@/components/shared/Modals/CreateForum'

import ModalAlert from '@/components/shared/Modals/ModalAlert'
import ModalTextField from '@/components/shared/Modals/ModalTextField'

export default {
    components: {
        TheProjectTypesFilter,
        ProjectTypesTable,
        CreateForum,
        ModalAlert,
        ModalTextField
    },
    data() {
        return {
            isLoading: true,
            types: [],
            searchOptions: {
                sort_by: 'created_at',
                sort_order: 'DESC',
                search_for: '',
                search_by: 'name'
            },
            actionName: null,
            modal: {},
            currentItem: null
        }
    },
    computed: {
        queryObject() {
            const { query } = this.$route

            return {
                ...query,
                page: +this.$route.query.page || 1,
                per_page: +this.$route.query.per_page || 10
            }
        },
        modalComponent() {
            return this.modal.name || ''
        }
    },
    methods: {
        async getForumList() {
            try {
                this.isLoading = true
                if (JSON.stringify(this.searchOptions) !== JSON.stringify(this.queryObject)) {
                    this.$router.replace({ query: { ...this.searchOptions } })
                }
                const response = await projectService.getList({ ...this.searchOptions })
                this.types = response
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.isLoading = false
            }
        },
        onFilterTable: debounce(async function (payload) {
            this.searchOptions = { ...this.searchOptions, ...payload }
            await this.getForumList()
        }, 300),
        async onSortTable(flag, option) {
            this.searchOptions = {
                ...this.searchOptions,
                ...option,
                page: 1
            }
            if (!flag) {
                await this.getForumList()
            }
        },
        async onCreateForum(payload) {
            try {
                await projectService.createForum({ ...payload })
                await this.getForumList()

                this.showCreator = false
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        onCreateType() {
            this.actionName = 'create_type'
            this.modal = {
                name: 'ModalTextField',
                title: 'Project type create',
                description: 'Do you want to create project type?',
                success_message: 'The project type is created',
                inputLabel: 'Project type name'
            }
            this.currentItem = null
        },
        onActionRow(item, action) {
            this.actionName = action.actionName
            this.modal = action.modal
            this.currentItem = item
        },
        onCloseModal() {
            this.modal = {}
            this.currentItem = {}
            this.actionName = ''
        },
        async onConfirmModal(payload) {
            try {
                switch (this.actionName) {
                case 'activate':
                    await projectService.activateType({
                        id: this.currentItem.id
                    })
                    this.changeTypeStatus(this.currentItem.id, 1)
                    break
                case 'deactivate':
                    await projectService.deactivateType({
                        id: this.currentItem.id
                    })
                    this.changeTypeStatus(this.currentItem.id, 0)
                    break
                case 'edit':
                    await projectService.updateType({
                        id: this.currentItem.id,
                        name: payload.details
                    })
                    this.changeTypeName(this.currentItem.id, payload.details)
                    break
                case 'create_type':
                    await projectService.createType({
                        name: payload.details
                    })
                    await this.getForumList()
                    break
                default:
                    return
                }
                eventBus.$emit('showSnackBar', this.modal.success_message, 'success')
                this.onCloseModal()
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        changeTypeStatus(id, status) {
            const find = this.types.findIndex((item) => item.id === id)

            this.types = this.types.map((item, index) => {
                if (index === find) {
                    return {
                        ...item,
                        status_active: status
                    }
                }
                return item
            })
        },
        changeTypeName(id, name) {
            const find = this.types.findIndex((item) => item.id === id)

            this.types = this.types.map((item, index) => {
                if (index === find) {
                    return {
                        ...item,
                        name
                    }
                }
                return item
            })
        }
    }
}
</script>

<style>

</style>
