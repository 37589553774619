<template>
    <v-container
        fluid
        class="py-10 px-10"
    >
        <v-row>
            <v-col cols="12 pr-10">
                <p class="text-subtitle-1">
                    Actions log
                </p>
                <v-progress-circular
                    v-if="isLoading"
                    indeterminate
                    color="primary"
                />
                <v-timeline
                    v-else-if="logsList.length"
                    dense
                    align-top
                    clipped
                >
                    <v-timeline-item
                        v-for="log in logsList"
                        :key="log.id"
                        class="mb-4 pb-0"
                        :color="getLogTypeComponent(log.type.type).color"
                        :icon="getLogTypeComponent(log.type.type).icon"
                        icon-color="white"
                        fill-dot
                    >
                        <component
                            :is="getLogTypeComponent(log.type.type).component"
                            :log="log"
                        />
                    </v-timeline-item>
                </v-timeline>
                <div
                    v-else
                    class="d-flex flex-column align-center w-full mt-5 mb-5"
                >
                    <div class="">
                        <img
                            src="@images/svg/no-data.svg"
                            alt=""
                        >
                    </div>
                    <p class="text-h6">
                        No data found
                    </p>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Api from '@/helpers/api/index.js'
import { eventBus } from '@/helpers/event-bus/'

import TakeOrder from '@/components/Orders/OrderLog/TakeOrder'
import DropOrderAdmin from '@/components/Orders/OrderLog/DropOrderAdmin'
import AssignOrder from '@/components/Orders/OrderLog/AssignOrder'
import NewFee from '@/components/Orders/OrderLog/NewFee'
import WriterTakesOrder from '@/components/Orders/OrderLog/WriterTakesOrder'
import DropOrderWriter from '@/components/Orders/OrderLog/DropOrderWriter'

export default {
    components: {
        TakeOrder,
        DropOrderAdmin,
        AssignOrder,
        NewFee,
        WriterTakesOrder,
        DropOrderWriter
    },
    data() {
        return {
            logsList: null,
            isLoading: true
        }
    },
    async created() {
        try {
            this.isLoading = true
            const { data } = await Api.get('/api/order-log/get-list', { orderid: this.$route.params.id })
            this.logsList = data.reverse()
        } catch (error) {
            eventBus.$emit('showSnackBar', error, 'error')
        } finally {
            this.isLoading = false
        }
    },
    methods: {
        getLogTypeComponent(projectType) {
            if (projectType === 'assign_bid') {
                return {
                    component: 'take-order',
                    icon: 'mdi-account-arrow-left-outline',
                    color: 'success'
                }
            }
            if (projectType === 'drop_order_admin') {
                return {
                    component: 'drop-order-admin',
                    icon: 'mdi-close',
                    color: 'error'
                }
            }
            if (projectType === 'assign_order') {
                return {
                    component: 'assign-order',
                    icon: 'mdi-account-arrow-left-outline',
                    color: 'warning'
                }
            }
            if (projectType === 'change_fee') {
                return {
                    component: 'new-fee',
                    icon: 'mdi-cash',
                    color: 'success'
                }
            }
            if (projectType === 'take_order') {
                return {
                    component: 'writer-takes-order',
                    icon: 'mdi-account-arrow-down-outline',
                    color: 'purple'
                }
            }
            if (projectType === 'drop_order_writer') {
                return {
                    component: 'drop-order-writer',
                    icon: 'mdi-account-arrow-up-outline',
                    color: 'error'
                }
            }
            return null
        }
    }
}
</script>

<style lang="scss">
    .log-details{
        background: #F4F8FD;
    }
    .border-left{
        border-left: 1px solid #D3D9E2;
    }
</style>
