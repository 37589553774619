<template>
    <div v-if="!loading">
        <div
            class="wrapper px-5 py-5"
        >
            <order-details-top
                :info="order"
                :toolbars="toolbars"
                @showBids="onShowBids"
                @toolbarActivated="onToolbarActivated"
            />
            <order-details-tabs
                :key="componentKey"
                class="mt-10 mb-15"
                :order="order"
                @update:counters="updateCounters"
                @uploadMainFiles="updateOrderData"
            />
            <!--            <toolbar-menu-->
            <!--                :toolbars="toolbars"-->
            <!--                @toolbarActivated="onToolbarActivated"-->
            <!--            />-->
            <!-- // Bids -->
            <bids-viewer
                v-if="showBids"
                :bids-info="order"
                @closeBids="onCloseBids"
                @deleteBid="onDeleteBid"
            />
            <!-- Batch viewer -->
            <batch-viewer
                v-if="showBatchPanel"
                :batchid="batchPanelId"
                @closeBatches="onCloseBatches"
            />
            <component
                :is="modalComponent"
                :title="modal.title"
                :description="modal.description"
                :input-label="modal.inputLabel"
                :current="order"
                :allowed-file-types="allowedFileTypes"
                :loading="loadingInModal"
                @closeModal="onCloseModal"
                @confirmModal="onConfirmModal"
            />
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { eventBus } from '@/helpers/event-bus/'

import OrderDetailsTop from '@/components/Orders/OrderDetails/OrderDetailsTop'
import OrderDetailsTabs from '@/components/Orders/OrderDetails/OrderDetailsTabs'

import BidsViewer from '@/components/SidePanels/BidsViewer'
import BatchViewer from '@/components/SidePanels/BatchViewer'

// MODALS
import ModalTextarea from '@/components/shared/Modals/ModalTextarea'
import ModalAlert from '@/components/shared/Modals/ModalAlert'
import ModalAssign from '@/components/shared/Modals/ModalAssign'
import ModalDeadline from '@/components/shared/Modals/ModalDeadline'
import ModalFee from '@/components/shared/Modals/ModalFee'
import ModalUpload from '@/components/shared/Modals/ModalUpload'
import ModalTransfer from '@/components/shared/Modals/ModalTransfer'
import ModalDropWriter from '@/components/shared/Modals/ModalDropWriter'
import ModalCreateTicketClient from '@/components/shared/Modals/ModalCreateTicketClient'
import ModalCreateTicketWriter from '@/components/shared/Modals/ModalCreateTicketWriter'

import Helper from '@/helpers/functions';

import {
    ALLOWED_FILE_TYPES, ORDER_TOOLBARS
} from '@/constants/Order'

import {
    ACTION_ASIGN_WRITER,
    ACTION_CREATE_NOTE,
    ACTION_GET_ORDER,
    ACTION_ORDER_DEADLINE,
    ACTION_ORDER_DROP,
    ACTION_ORDER_FEE,
    ACTION_ORDER_REVISION,
    ACTION_UPLOAD_ORDER_FILE,
    ACTION_ORDER_CHANGE_STATUS
} from '@/store/modules/orders/action-types';

import {
    ACTION_CREATE_BOOKMARK
} from '@/store/modules/bookmarks/action-types';

import {
    DELETE_BID,
    CLEAR_ORDER_LIST
} from '@/store/modules/orders/mutation-types';

import {
    ACTION_CREATE_TICKET,
    ACTION_CREATE_TICKET_WRITER
} from '@/store/modules/support/action-types';

const {
    mapActions: mapAdminActions,
    mapMutations: mapOrderMutations
} = createNamespacedHelpers('orders');

const {
    mapActions: mapBookmarkActions
} = createNamespacedHelpers('bookmarks');

const {
    mapActions: mapSupportActions
} = createNamespacedHelpers('support');

export default {
    components: {
        OrderDetailsTop,
        OrderDetailsTabs,
        // ToolbarMenu,
        BidsViewer,
        BatchViewer,
        // MODALS
        ModalAssign,
        ModalTextarea,
        ModalAlert,
        ModalDeadline,
        ModalFee,
        ModalUpload,
        ModalTransfer,
        ModalDropWriter,
        ModalCreateTicketClient,
        ModalCreateTicketWriter
    },
    data() {
        return {
            loading: true,
            openedModal: '',
            modal: {},
            order: {},
            componentKey: 1,
            showBids: false,
            bidsOrderid: null,
            // batch panel
            showBatchPanel: false,
            batchPanelId: null,
            loadingInModal: false,
            showPanel: false
        }
    },
    computed: {
        toolbars() {
            if (!this.order.writer?.id) {
                return ORDER_TOOLBARS.filter((item) => !(item.name === 'message' || item.name === 'drop'))
            }
            return ORDER_TOOLBARS
        },
        modalComponent() {
            return this.modal.name || ''
        },
        allowedFileTypes() {
            return ALLOWED_FILE_TYPES
        }
    },
    watch: {
        showPanel(val) {
            Helper.lockHtml(val)
        }
    },
    beforeRouteLeave(to, from, next) {
        if (!to.name.includes('order')) {
            this[CLEAR_ORDER_LIST]()
        }
        next()
    },
    async activated() {
        await this.orderData()
    },
    methods: {
        ...mapAdminActions({
            ACTION_GET_ORDER,
            ACTION_UPLOAD_ORDER_FILE,
            ACTION_ASIGN_WRITER,
            ACTION_ORDER_FEE,
            ACTION_ORDER_DEADLINE,
            ACTION_ORDER_DROP,
            ACTION_ORDER_REVISION,
            ACTION_CREATE_NOTE,
            ACTION_ORDER_CHANGE_STATUS
        }),
        ...mapBookmarkActions({
            ACTION_CREATE_BOOKMARK
        }),
        ...mapOrderMutations({
            DELETE_BID,
            CLEAR_ORDER_LIST
        }),
        ...mapSupportActions({
            ACTION_CREATE_TICKET_WRITER,
            ACTION_CREATE_TICKET
        }),
        onToolbarActivated(payload) {
            // this.actionName = payload.type
            this.actionName = payload.actionName
            this.modal = payload.modal
        },
        onCloseModal() {
            this.modal = {}
        },
        async orderData() {
            this.loading = true
            const { id } = this.$route.params
            const data = await this[ACTION_GET_ORDER](
                {
                    orderid: id
                }
            )
            this.loading = false
            this.order = data
        },
        async updateOrderData() {
            const { id } = this.$route.params
            const data = await this[ACTION_GET_ORDER](
                {
                    orderid: id
                }
            )
            this.order = { ...this.order, ...data }
        },
        async onConfirmModal(payload) {
            try {
                this.loadingInModal = true
                switch (this.actionName) {
                case 'upload_files':
                    await this.uploadFiles(payload)
                    break

                case 'bookmark':
                    await this.createBookmark(payload)
                    break

                case 'assign':
                    await this.assignWriter(payload)
                    break

                case 'Fee':
                    await this.orderFee(payload)
                    break

                case 'deadline':
                    await this.orderDeadline(payload)
                    break

                case 'drop':
                    await this.orderWriterDrop(payload)
                    break

                case 'notes':
                    await this.orderCreateNote(payload)
                    break

                case 'client':
                    await this.createClientTicket(payload)
                    break
                case 'change_status':
                    await this.orderChangeStatus()
                    break
                case 'writer':
                    await this.createWriterTicket(payload)
                    break

                case 'revision':
                    await this.orderCreateRevision(payload)
                    break

                default:
                    return
                }
                eventBus.$emit('showSnackBar', this.modal.success_message, 'success')
                await this.updateOrderData()
                this.updateChildRoute()
                this.onCloseModal()
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.loadingInModal = false
            }
        },
        async orderChangeStatus() {
            const { id } = this.$route.params
            await this[ACTION_ORDER_CHANGE_STATUS]({ order_id: id })
        },
        async orderCreateNote(payload) {
            const { id } = this.$route.params
            await this[ACTION_CREATE_NOTE]({ orderid: id, ...payload })
        },
        async orderWriterDrop(payload) {
            const { id } = this.$route.params
            await this[ACTION_ORDER_DROP]({ orderid: id, details: payload.reason })
        },
        async orderCreateRevision(payload) {
            const { id } = this.$route.params
            await this[ACTION_ORDER_REVISION]({ orderid: id, ...payload })
        },
        async createBookmark(payload) {
            const { id } = this.$route.params
            await this[ACTION_CREATE_BOOKMARK]({ ...payload, referenceids: [id] })
        },
        async uploadFiles({ formData, callback }) {
            const { id } = this.$route.params
            formData.append('orderid', id)
            await this[ACTION_UPLOAD_ORDER_FILE]({
                formData, callback
            })
        },
        async assignWriter(payload) {
            const { id } = this.$route.params
            await this[ACTION_ASIGN_WRITER]({ writer_id: payload.sw_id, order_id: id })
        },
        async orderFee(payload) {
            const { id } = this.$route.params
            await this[ACTION_ORDER_FEE]({ ...payload, orderid: id })
            this.order = {
                ...this.order,
                sw_total: payload.cost.toFixed(2)
            }
        },
        async orderDeadline(payload) {
            const { id } = this.$route.params
            await this[ACTION_ORDER_DEADLINE]({ ...payload, orderid: id })
            this.order = {
                ...this.order,
                due_at: payload.deadline
            }
        },
        async createClientTicket(payload) {
            await this[ACTION_CREATE_TICKET]({
                ...payload
            })
        },
        async createWriterTicket(payload) {
            await this[ACTION_CREATE_TICKET_WRITER]({
                ...payload
            })
        },
        updateChildRoute() {
            this.componentKey += 1
        },

        // BIDS
        onShowBids(orderid) {
            this.showBids = true
            this.showPanel = true
            this.bidsOrderid = orderid
        },
        onCloseBids() {
            this.showBids = false
            this.showPanel = false
            this.bidsOrderid = null
        },
        onDeleteBid(payload) {
            this[DELETE_BID](payload)
        },

        // Batches
        onShowBatches(batchid) {
            this.showBatchPanel = true
            this.batchPanelId = batchid
        },
        onCloseBatches() {
            this.showBatchPanel = false
            this.batchPanelId = null
        },

        // Counters
        updateCounters(e) {
            switch (e.type) {
            case 'notes':
                this.order.counters = {
                    ...this.order.counters,
                    notes_count: this.order.counters.notes_count + e.counter
                }
                break;
            case 'message':
                this.order.counters = {
                    ...this.order.counters,
                    messages_count: this.order.counters.messages_count + e.counter
                }
                break;
            case 'tickets':
                this.order.counters = {
                    ...this.order.counters,
                    tickets_writer_count: this.order.counters.tickets_writer_count + e.counter
                }
                break;
            case 'tickets-client':
                this.order.counters = {
                    ...this.order.counters,
                    tickets_client_count: this.order.counters.tickets_client_count + e.counter
                }
                break;
            case 'files_main_count':
                this.order.counters = {
                    ...this.order.counters,
                    files_main_count: this.order.counters.files_main_count + e.counter
                }
                break;
            case 'files_add_count':
                this.order.counters = {
                    ...this.order.counters,
                    files_add_count: this.order.counters.files_add_count + e.counter
                }
                break;

            default:
                break;
            }
        }
    }
}
</script>

<style>

</style>
