<template>
    <div class="wrapper py-5">
        <v-container
            fluid
            class="white rounded"
        >
            <v-row class="mb-5" />

            <!-- <div class="">
                <the-users-filter
                    :search-options="searchOptions"
                    @filterTable="onFilterTable"
                />
            </div> -->

            <v-data-table
                v-model="selected"
                :headers="tableHeader"
                :items="emailsData"
                item-key="id"
                :server-items-length="total"
                :loading="loading"
                hide-default-footer
                calculate-widths
                class="mt-10"
                must-sort
                :sort-by="sortBy"
                :sort-desc="sortDesc"
                @update:options="onSortTable"
            >
                <template v-slot:body="{ items }">
                    <table-skeleton
                        v-if="loading"
                        :per-page="searchOptions.per_page"
                        :column-count="tableHeaderLength"
                    />
                    <tbody
                        v-else
                    >
                        <tr
                            v-for="item in items"
                            :key="item.id"
                            class="hovered-row"
                            :class="rowClasses(item)"
                            @mouseenter="selectItem(item.id)"
                            @mouseleave="unSelectItem()"
                        >
                            <!-- GENERAL -->
                            <td>
                                <div class="d-flex flex-column">
                                    <p class="grey--text mb-1 d-flex align-center">
                                        {{ item.id }}
                                    </p>
                                </div>
                            </td>
                            <!-- Status -->
                            <td class="px-0 ">
                                <p class="mb-2">
                                    {{ item.status }}
                                </p>
                                <v-progress-linear
                                    v-if="item.status !== 'NEW'"
                                    color="teal"
                                    :buffer-value="1"
                                    :value="item.progress * 100"
                                    stream
                                />
                            </td>
                            <!-- Domain -->
                            <td class="px-0 ">
                                <domain-favicon
                                    :domain="item.domain"
                                />
                            </td>
                            <!-- Admin -->
                            <td>
                                <div class="d-flex flex-column">
                                    <avatar-rolles
                                        access-status="admin"
                                        :firstname="item.admin.firstname"
                                        :lastname="item.admin.lastname"
                                        :avatar-url="item.admin.avatar_url"
                                        :avatar-fallback="item.admin.avatar_fallback"
                                        avatar-size="45"
                                        class="mr-4"
                                    />
                                </div>
                            </td>
                            <!-- Recipients count-->
                            <td>
                                <div
                                    style="cursor: pointer;"
                                    @click="openRecipietns(item.id)"
                                >
                                    <p class="success--text">
                                        {{ item.recipients_count }}
                                    </p>
                                </div>
                            </td>
                            <!-- Created -->
                            <td>
                                <div>
                                    <p>{{ $date.shortText(item.created_at) }}</p>
                                </div>
                            </td>
                            <!-- Started -->
                            <td>
                                <span>
                                    <p> {{ `${item.start_at === '0000-00-00 00:00:00' ? '—' : $date.shortText(item.start_at) }` }}</p>
                                </span>
                            </td>
                            <td>
                                <v-btn
                                    color="btn-email primary"
                                    x-large
                                    @click="openEmail(item.payload)"
                                >
                                    Show
                                </v-btn>
                            </td>
                            <td class="py-0">
                                <div class="d-flex align-center">
                                    <table-rows-menu
                                        :toolbars="toolbars"
                                        :item="item"
                                        class="mt-2"
                                        @menuActivated="onMenuActivated"
                                    />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-data-table>

            <pagination-with-perpage
                v-if="!loading"
                :list-lenght="emailsData.length"
                :last-page="lastPage"
                :search-options="{ page: searchOptions.page, per_page: searchOptions.per_page }"
                :total-visible="7"
                @updatePage="paginationPageEvent"
                @updatePerpage="onFilterTable"
            />

            <div
                v-if="emailsData && !emailsData.length && !loading "
                class="d-flex flex-column align-center w-full mt-5 mb-5"
            >
                <div class="">
                    <img
                        src="@images/svg/no-data.svg"
                        alt=""
                    >
                </div>
                <p class="text-h6">
                    No emails found
                </p>
            </div>
        </v-container>

        <!-- // recipients -->
        <recipients-viewer
            v-if="showRecipients"
            :bulkmail-id="bulkmailId"
            @closeRecipients="closeRecipients"
        />

        <!-- // email -->
        <email-viewer
            v-if="showEmail"
            :email="email"
            @closeEmail="closeEmail"
        />

        <!-- Modals -->
        <component
            :is="modalComponent"
            :title="modal.title"
            :description="modal.description"
            @closeModal="onCloseModal"
            @confirmModal="onConfirmModal"
        />
    </div>
</template>

<script>

import { eventBus } from '@/helpers/event-bus/'
import Api from '@/helpers/api/index.js'
import { removeEmptyParamsFromRequest } from '@/helpers/utils/index.js'
import { nameCapitalized } from '@/helpers/capitalized/index'

import {
    EMAILS_TABLE_HEADER, EMAILS_TOOLBARS
} from '@/constants/Emails'

import PaginationWithPerpage from '@/components/shared/Tables/PaginationWithPerpage';
import TableSkeleton from '@/components/shared/Tables/TableSkeleton';
import RecipientsViewer from '@/components/SidePanels/RecipientsViewer'
import EmailViewer from '@/components/SidePanels/EmailViewer'

import ModalAlert from '@/components/shared/Modals/ModalAlert'
import TableRowsMenu from '@/components/shared/Tables/TableRowsMenu'
import AvatarRolles from '@/components/shared/Avatars/AvatarRolles'
import DomainFavicon from '@/components/shared/Helpers/DomainFavicon'

export default {
    components: {
        PaginationWithPerpage,
        TableSkeleton,
        RecipientsViewer,
        EmailViewer,
        ModalAlert,
        TableRowsMenu,
        AvatarRolles,
        DomainFavicon
    },
    metaInfo: {
        title: 'Mailing list'
    },
    data() {
        return {
            selected: [],
            loading: true,
            searchOptions: {
                page: 1,
                per_page: 10,
                sort_by: 'created_at',
                sort_order: 'DESC',
                from: '',
                to: ''
            },
            sort: {
                sort_by: 'created_at',
                sort_order: 'DESC'
            },
            showRecipients: false,
            showEmail: false,
            bulkmailId: null,
            email: null,
            emailsData: [],
            hoveredItem: {},
            currentItem: {},
            openedRow: [],
            isFirstLoad: true,
            // modals logic
            currentAction: '',
            currentId: null,
            modal: {},
            lastPage: Infinity,
            total: null
        }
    },
    computed: {
        tableHeader() {
            return EMAILS_TABLE_HEADER
        },
        tableHeaderLength() {
            return EMAILS_TABLE_HEADER.length
        },
        toolbars() {
            return EMAILS_TOOLBARS
        },
        queryObject() {
            const { query } = this.$route

            return {
                ...query,
                page: +this.$route.query.page || 1,
                per_page: +this.$route.query.per_page || 10
            }
        },
        sortBy() {
            return this.$route.query.sort_by || 'created_at'
        },
        sortDesc() {
            return this.$route.query.sort_order === 'ASC'
        },
        modalComponent() {
            return this.modal.name || ''
        }
    },
    created() {
        this.searchOptions = {
            ...this.searchOptions,
            ...this.queryObject
        }
        this.getEmailsList()
    },
    methods: {
        nameCapitalized,
        openFullRow(id) {
            if (this.openedRow.includes(id)) {
                this.openedRow = this.openedRow.filter((item) => item !== id)
            } else {
                this.openedRow.push(id)
            }
        },
        closeEmail() {
            this.showEmail = false
        },
        openEmail(email) {
            this.showEmail = true
            this.email = JSON.parse(email)
        },
        closeRecipients() {
            this.showRecipients = false
        },
        openRecipietns(id) {
            this.showRecipients = true
            this.bulkmailId = id
        },
        // HOVER ON TABLE ROW
        selectItem(item) {
            this.hoveredItem = item
        },
        rowClasses(item) {
            return {
                'table-row--selected-once': this.currentItem?.id === item.id
            }
        },
        unSelectItem() {
            this.hoveredItem = null
        },
        // TABLE LOGIC
        async getEmailsList() {
            try {
                this.loading = true
                if (JSON.stringify(this.searchOptions) !== JSON.stringify(this.queryObject)) {
                    this.$router.replace({ query: { ...this.searchOptions } })
                }
                const { data } = await Api.get('/api/bulk-mail/list', removeEmptyParamsFromRequest({ ...this.searchOptions }))
                this.emailsData = data.data
                this.lastPage = data.last_page
                this.total = data.total
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.loading = false
            }
        },
        paginationPageEvent(e) {
            this.searchOptions.page = e
            this.getEmailsList()
        },
        onFilterTable(payload) {
            this.searchOptions = { ...this.searchOptions, ...payload, page: 1 }
            this.getEmailsList()
        },
        onSortTable(e) {
            // eslint-disable-next-line prefer-destructuring
            this.sort.sort_by = e.sortBy[0] || 'start_at'
            this.sort.sort_order = e.sortDesc[0] ? 'ASC' : 'DESC'
            if (!this.isFirstLoad) {
                this.searchOptions = {
                    ...this.searchOptions,
                    ...this.sort,
                    page: 1
                }
                this.getEmailsList()
            }
            this.isFirstLoad = false
        },
        onMenuActivated(item, action) {
            this.currentAction = action.actionName
            this.modal = action.modal
            this.currentId = item.id
        },
        onCloseModal() {
            this.modal = {}
            this.currentAction = ''
            this.currentId = null
        },
        async onConfirmModal() {
            try {
                switch (this.currentAction) {
                case 'send-to-me':
                    await this.sendToMe()
                    break;
                case 'send-to-clients':
                    await this.sendToClients()
                    break;
                case 'edit':
                    this.$router.push({ name: 'emails-edit', params: { id: this.currentId } })
                    return
                case 'delete':
                    await this.deleteEmailing()
                    break;
                default:
                    break;
                }
                eventBus.$emit('showSnackBar', this.modal.success_message, 'success')
                this.onCloseModal()
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        async sendToMe() {
            await Api.post('/api/bulk-mail/send-to-me', { bulkmail_id: this.currentId })
        },
        async sendToClients() {
            await Api.post('/api/bulk-mail/send-to-clients', { bulkmail_id: this.currentId })
        },
        async deleteEmailing() {
            await Api.delete('/api/bulk-mail/delete', { bulkmail_id: this.currentId })
            this.emailsData = this.emailsData.filter((it) => it.id !== this.currentId)
        }
    }
}
</script>

<style lang="scss">
    .btn-email {
        height: 30px !important;
    }
</style>
