<template>
    <div class="">
        <v-data-table
            :headers="tableHeader"
            :items="tableItems"
            item-key="ticketid"
            :loading="loading"
            hide-default-footer
            calculate-widths
            class="mt-4"
            must-sort
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            @update:options="sortTable"
        >
            <template v-slot:body="{ items }">
                <tbody>
                    <tr
                        v-for="(item, index) in items"
                        :key="item.id"
                    >
                        <!-- Index -->
                        <td class="td_index">
                            {{ index + 1 }}
                        </td>
                        <!-- PRIORITY -->
                        <td class="td_priority">
                            <b>{{ item.name }}</b>
                        </td>
                        <!-- CREATED -->
                        <td class="td_created max-w-130 break-word">
                            {{ $date.humanizeDate(item.created_at) }}
                        </td>
                        <!-- SUBJECT -->
                        <td
                            class="td_subject"
                        >
                            <div
                                v-if="item.admin"
                                class="d-flex mb-4"
                            >
                                <avatar-rolles
                                    access-status="admin"
                                    :firstname="item.admin.firstname"
                                    :lastname="item.admin.lastname"
                                    :avatar-url="item.admin.avatar_url"
                                    :avatar-fallback="item.admin.avatar_fallback"
                                    avatar-size="45"
                                />
                                <div class="ml-4">
                                    <p class="mb-0">
                                        {{ item.admin.firstname }} {{ item.admin.lastname }}
                                    </p>
                                    <p class="mb-0">
                                        Admin ID: {{ item.admin.id }}
                                    </p>
                                </div>
                            </div>
                        </td>

                        <!-- SUBJECT -->
                        <td
                            class="td_subject"
                        >
                            <img
                                v-if="item.status_active"
                                src="@images/svg/trusted_writer.svg"
                                alt="trusted_writer"
                                width="21"
                                height="21"
                                class="mt-1"
                            >
                            <img
                                v-else
                                src="@images/svg/new_writer.svg"
                                alt="trusted_writer"
                                width="21"
                                height="21"
                                class="mt-1"
                            >
                        </td>
                        <td
                            @click.stop
                        >
                            <table-rows-menu
                                :toolbars="toolbars"
                                :item="item"
                                class="mt-2"
                                @menuActivated="onMenuActivated"
                            />
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-data-table>
        <div
            v-if="!tableItems.length"
            class="d-flex flex-column align-center w-full mt-5 mb-5"
        >
            <div class="">
                <img
                    src="@images/svg/no-data.svg"
                    alt=""
                >
            </div>
            <p class="text-h6">
                No types found
            </p>
        </div>
    </div>
</template>

<script>
import AvatarRolles from '@/components/shared/Avatars/AvatarRolles'
import TableRowsMenu from '@/components/shared/Tables/TableRowsMenu'

import {
    TYPES_TABLE_HEAD, TYPES_TOOLBARS
} from '@/constants/ProjectTypes'

export default {
    components: {
        AvatarRolles,
        TableRowsMenu
    },
    props: {
        tableItems: {
            type: Array,
            reqiured: true
        },
        loading: {
            type: Boolean,
            default: false
        },
        currentItem: {
            type: Object
        }
    },
    data() {
        return {
            selected: [],
            hoveredItem: null,
            page: null,
            flag: true
        }
    },
    computed: {
        tableHeader() {
            return TYPES_TABLE_HEAD
        },
        tableHeaderLength() {
            return TYPES_TABLE_HEAD.length + 1
        },
        toolbars() {
            return TYPES_TOOLBARS
        },
        sortBy() {
            return this.$route.query.sort_by || 'created_at'
        },
        sortDesc() {
            return this.$route.query.sort_order === 'ASC'
        }
    },
    watch: {
        selected() {
            this.$emit('selected', this.selected)
        },
        tableItems: {
            handler(newVal) {
                this.selected = this.selected.map((select) => {
                    const find = newVal.find((item) => item.ticketid === select?.ticketid)
                    return find
                })
            },
            deep: true
        },
        currentPage() {
            this.page = this.currentPage
        }
    },
    created() {
        this.page = this.currentPage
    },
    methods: {
        paginationPageEvent(e) {
            this.$emit('paginate', e)
        },
        sortTable(e) {
            const {
                sortBy,
                sortDesc
            } = e

            this.$emit('sortTable', this.flag, {
                sort_by: sortBy[0],
                sort_order: sortDesc[0] === true ? 'ASC' : 'DESC'
            })
            this.flag = false
        },
        onMenuActivated(item, modal) {
            this.$emit('actionRow', item, modal)
        }
    }

}
</script>

<style lang="scss">
    .max-w-130{
        max-width: 130px;
    }
    .message{
        &__data{
            width: calc(100% - 75px);
            background: #f2f6f9 ;
            border-radius: 12px;
            &--admin{
                background: #ddf1ff;
            }
            &--accent{
                background: #d7ebf7;
            }
        }
    }
</style>
