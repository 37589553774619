<template>
    <div>
        <div>
            <p class="text-subtitle-2 mb-0">
                {{ log.type.title }}
            </p>
            <p>
                {{ $date.fromNowFullText(log.created_at) }}
                ({{ $date.shortText(log.created_at) }})
            </p>
        </div>
        <div class="d-flex align-center mb-5">
            <router-link
                class="text-decoration-none black--text"
                :to="{name: 'writer-profile', params: {sw_id: log.creator.id}}"
            >
                <div class="d-flex align-center">
                    <avatar-rolles
                        access-status="admin"
                        :firstname="log.creator.firstname"
                        :lastname="log.creator.lastname"
                        :avatar-url="log.creator.avatar_url && log.creator.avatar"
                        avatar-size="50"
                    />
                    <p class="mb-0 ml-3">
                        <b>{{ log.creator.firstname }} {{ log.creator.lastname }}</b>
                        <br>
                        Admin ID: <b>{{ log.creator.id }}</b>
                    </p>
                </div>
            </router-link>
        </div>
        <div class="log-details rounded px-4 py-4">
            <p class="mb-0">
                {{ log.details }}
            </p>
        </div>
    </div>
</template>

<script>
import AvatarRolles from '@/components/shared/Avatars/AvatarRolles'

export default {
    components: {
        AvatarRolles
    },
    props: {
        log: {
            type: Object,
            required: true
        }
    }
}
</script>

<style lang="scss">

</style>
