<template>
    <v-container fluid>
        <v-row>
            <template>
                <!--                <v-tabs-->
                <!--                    v-model="activeTab"-->
                <!--                    show-arrows-->
                <!--                >-->
                <!--                    <template v-for="tab in tabs">-->
                <!--                        <v-tab-->
                <!--                            v-if="!tab.hidden"-->
                <!--                            :key="tab.path"-->
                <!--                            :to="tab.path"-->
                <!--                        >-->
                <!--                            {{ tab.text }}-->
                <!--                            <span-->
                <!--                                v-if="tab.total !== null"-->
                <!--                                class="tab-total"-->
                <!--                            >-->
                <!--                                {{ tab.total }}-->
                <!--                            </span>-->
                <!--                        </v-tab>-->
                <!--                    </template>-->
                <!--                </v-tabs>-->
                <select-tabs
                    v-model="activeTab"
                    :items="tabs"
                />
                <KeepAlive
                    :max="3"
                >
                    <router-view
                        :key="$route.fullPath"
                        class="white"
                        :orderid="orderId"
                        :order="order"
                        :client="{
                            id:order.clientid,
                            ordersCount:order.client_orders_count,
                            clientCountry:order.client_country,
                            firstname:order.client_firstname,
                            lastname:order.client_lastname,
                            avatar:order.client_avatar
                        }"
                        @createTicket="onCreateTicket"
                        @update:counters="updateCounters"
                        @uploadMainFiles="uploadMainFiles"
                    />
                </KeepAlive>
            </template>
        </v-row>
    </v-container>
</template>

<script>

import selectTabs from '@/components/shared/SelectTabs.vue';

export default {
    name: 'OrderDetailsTabs',
    components: {
        selectTabs
    },
    props: {
        order: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            activeTab: ''
        }
    },
    computed: {
        orderId() {
            return this.order.orderid ?? this.$route.params.id
        },
        tabs() {
            return [
                {
                    text: 'Details',
                    key: 'details',
                    path: `/orders/${this.orderId}`,
                    total: null
                },
                {
                    text: 'Notes',
                    key: 'comm_log',
                    path: `/orders/${this.orderId}/notes`,
                    total: this.order.counters.notes_count
                },
                {
                    text: 'Messages',
                    key: 'comm_log',
                    path: `/orders/${this.orderId}/comment-log`,
                    total: this.order.counters.messages_count
                },
                {
                    text: 'Client Tickets',
                    key: 'client',
                    path: `/orders/${this.orderId}/client-chat`,
                    total: this.order.counters.tickets_client_count
                },
                {
                    text: 'Writer Tickets',
                    key: 'writer',
                    path: `/orders/${this.orderId}/writer-chat`,
                    total: this.order.counters.tickets_writer_count,
                    hidden: this.order.sw_id === null
                },
                // {
                //     text: 'Log',
                //     key: 'logs',
                //     path: `/orders/${this.orderId}/logs`,
                //     total: this.order.activity_log_count
                // },
                {
                    text: 'Files',
                    key: 'files',
                    path: `/orders/${this.orderId}/files`,
                    total: this.order.counters.files_main_count
                },
                {
                    text: 'Additional files',
                    key: 'files_add',
                    path: `/orders/${this.orderId}/files-add`,
                    total: this.order.counters.files_add_count
                },
                {
                    text: 'Testimonials',
                    key: 'feedback',
                    path: `/orders/${this.orderId}/feedback`,
                    total: this.order.counters.testimonials_count
                },
                // {
                //     text: 'Gateway',
                //     key: 'gateway',
                //     path: `/orders/${this.orderId}/gateway`
                // }
                {
                    text: 'Activity',
                    key: 'feedback',
                    path: `/orders/${this.orderId}/activity`,
                    total: this.order.activity.length || null
                }
            ]
        }
    },
    created() {
        this.activeTab = this.tabs[0].path
    },
    deactivated() {
        this.$destroy()
    },
    methods: {
        onCreateTicket(payload) {
            this.$emit('createTicket', payload)
        },
        updateCounters(e) {
            this.$emit('update:counters', e)
        },
        uploadMainFiles() {
            this.$emit('uploadMainFiles')
        }
    }
}
</script>

<style lang="scss">
    .v-tabs-items{
        width: 100%;
    }
    .tab-total {
        padding: 4px 10px;
        background: rgba(79, 79, 79, 0.1);
        border-radius: 4px;
        margin-left: 10px;
    }

    .v-tab {
        min-width: 180px;
    }
</style>
