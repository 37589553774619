<template>
    <modal
        :show-modal="showModal"
        @closeModal="onCloseModal"
        @confirmModal="onConfirmModal"
    >
        <template #title>
            <span>Create forum</span>
        </template>
        <template #body>
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
            >
                <v-text-field
                    v-model="title"
                    outlined
                    color="accent"
                    label="Title"
                    :rules="valid_title"
                    required
                />
                <v-text-field
                    v-model="referenceid"
                    outlined
                    color="accent"
                    label="Reference ID"
                    :rules="valid_referenceid"
                    required
                />
                <v-select
                    v-model="status_priority"
                    :items="priorities"
                    :rules="valid_status_priority"
                    label="Priority"
                    outlined
                    required
                >
                    <template
                        slot="selection"
                        slot-scope="{ item }"
                    >
                        <img
                            v-if="item.value !== 'all'"
                            class="mr-2"
                            :src="require(`@images/svg/${getIcon(item.value)}`)"
                            :alt="item.value"
                        >
                        {{ item.text }}
                    </template>
                    <template
                        slot="item"
                        slot-scope="{ item }"
                    >
                        <img
                            v-if="item.value !== 'all'"
                            class="mr-2"
                            :src="require(`@images/svg/${getIcon(item.value)}`)"
                            :alt="item.value"
                        >
                        {{ item.text }}
                    </template>
                </v-select>

                <v-select
                    v-model="allowed_ids"
                    :items="getterAdminsList"
                    item-value="id"
                    outlined
                    :menu-props="{ bottom: true, offsetY: true }"
                    label="Allowed IDs"
                    multiple
                    required
                >
                    <template
                        slot="selection"
                        slot-scope="{ item }"
                    >
                        <div
                            class="d-flex my-2 mx-2"
                        >
                            <avatar-rolles
                                access-status="admin"
                                :firstname="item.firstname"
                                :lastname="item.lastname"
                                :avatar-url="item.avatar_url"
                                :avatar-fallback="item.avatar_fallback"
                                avatar-size="35"
                            />
                            <div class="ml-4">
                                <p class="mb-0 text-caption">
                                    {{ item.firstname }} {{ item.lastname }}
                                </p>
                                <p class="mb-0 text-caption">
                                    Admin ID: {{ item.id }}
                                </p>
                            </div>
                            <v-btn
                                icon
                                @click.stop="deleteFromAllowed(item.id)"
                            >
                                <v-icon
                                    size="15"
                                    color="error"
                                >
                                    mdi-close
                                </v-icon>
                            </v-btn>
                        </div>
                    </template>
                    <template
                        slot="item"
                        slot-scope="{ item }"
                    >
                        <div class="d-flex my-2">
                            <avatar-rolles
                                access-status="admin"
                                :firstname="item.firstname"
                                :lastname="item.lastname"
                                :avatar-url="item.avatar_url"
                                :avatar-fallback="item.avatar_fallback"
                                avatar-size="35"
                            />
                            <div class="ml-4">
                                <p class="mb-0 text-caption">
                                    {{ item.firstname }} {{ item.lastname }}
                                </p>
                                <p class="mb-0 text-caption">
                                    Admin ID: {{ item.id }}
                                </p>
                            </div>
                        </div>
                    </template>
                </v-select>
            </v-form>
        </template>
    </modal>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex';
import Modal from '@/components/shared/Modals/Modal'
import {
    SUPPORT_PRIORITY
} from '@/constants/Support'
import {
    ACTION_GET_ADMIN_LIST
} from '@/store/modules/admin/action-types';

import AvatarRolles from '@/components/shared/Avatars/AvatarRolles'

const {
    mapActions: mapAdminsActions
} = createNamespacedHelpers('admins');
export default {
    components: {
        Modal,
        AvatarRolles
    },
    data() {
        return {
            title: '',
            valid_title: [
                (v) => !!v || 'Title is required'
            ],
            status_priority: '',
            valid_status_priority: [
                (v) => !!v || 'Status priority is required'
            ],
            referenceid: '',
            valid_referenceid: [
                (v) => !!v || 'Referenceid is required'
            ],
            allowed_ids: [],
            showModal: true,
            valid: true
        }
    },
    computed: {
        priorities() {
            return SUPPORT_PRIORITY.filter((item) => item.value !== 'all')
        },
        ...mapGetters('admins', [
            'getterAdminsList'
        ])
    },
    async created() {
        await this[ACTION_GET_ADMIN_LIST]({ ...this.searchOptions })
    },
    methods: {
        onCloseModal() {
            this.showModal = false
            this.$emit('closeModal', true)
        },
        onConfirmModal() {
            this.$refs.form.validate()
            this.$nextTick(() => {
                if (this.valid) {
                    this.$emit('createForum', {
                        allowed_ids: [...this.allowed_ids].join(' '),
                        title: this.title,
                        status_priority: this.status_priority,
                        referenceid: this.referenceid
                    })
                }
            })
        },
        getIcon(str) {
            switch (str) {
            case 'NORMAL':
                return 'status_medium.svg'
            case 'HIGH':
                return 'status_high.svg'
            case 'LOW':
                return 'status_low.svg'
            default:
                return ''
            }
        },
        ...mapAdminsActions({
            ACTION_GET_ADMIN_LIST
        }),
        deleteFromAllowed(id) {
            const idx = this.allowed_ids.indexOf(id)
            this.allowed_ids.splice(idx, 1)
            this.allowed_ids = [...this.allowed_ids]
        }
    }
}
</script>

<style>

</style>
