<template>
    <div>
        <div>
            <p class="text-subtitle-2 mb-0">
                {{ log.type.title }}
            </p>
            <p>
                {{ $date.fromNowFullText(log.created_at) }}
                ({{ $date.shortText(log.created_at) }})
            </p>
        </div>
        <div class="d-flex align-center mb-5">
            <div class="d-flex align-center">
                <avatar-rolles
                    access-status="admin"
                    :firstname="log.creator.firstname"
                    :lastname="log.creator.lastname"
                    :avatar-url="log.creator.avatar_url && log.creator.avatar"
                    avatar-size="50"
                />
                <p class="mb-0 ml-3">
                    <b>{{ log.creator.firstname }} {{ log.creator.lastname }}</b>
                    <br>
                    Admin ID: <b>{{ log.creator.id }}</b>
                </p>
            </div>
            <div class="arrow ml-5 mr-5">
                <div class="arrow_helper" />
            </div>
            <router-link
                class="text-decoration-none black--text"
                :to="{name: 'writer-profile', params: {sw_id: log.action_user.id}}"
            >
                <div class="d-flex align-center">
                    <avatar-rolles
                        access-status="writer"
                        :firstname="log.action_user.firstname"
                        :lastname="log.action_user.lastname"
                        :avatar-url="log.action_user.avatar_url && log.action_user.avatar"
                        avatar-size="50"
                    />
                    <p class="mb-0 ml-3">
                        <b>{{ log.action_user.firstname }} {{ log.action_user.lastname }}</b>
                        <br>
                        Writer ID: <b>{{ log.action_user.id }}</b>
                    </p>
                </div>
            </router-link>
        </div>
        <div class="log-details rounded px-4 py-4">
            <p class="mb-0">
                {{ log.details }}
            </p>
        </div>
    </div>
</template>

<script>
import AvatarRolles from '@/components/shared/Avatars/AvatarRolles'

export default {
    components: {
        AvatarRolles
    },
    props: {
        log: {
            type: Object,
            required: true
        }
    }
}
</script>

<style lang="scss">
    .arrow {
        position: relative;
        height: 2px;
        width: 100px;
        background: #4caf50 ;
        &_helper{
            border: solid #4caf50 ;
            border-width: 0 2px 2px 0;
            display: inline-block;
            padding: 3px;
            transform: rotate(-45deg);
            position: absolute;
            right: 0;
            top: -3px;
        }
    }
</style>
