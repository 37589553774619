<template>
    <v-form
        ref="form"
        v-model="valid"
        lazy-validation
    >
        <!-- // DATE TIME -->
        <v-datetime-picker
            v-model="mail_start_at"
            label="Start at"
            :text-field-props="{
                outlined: true
            }"
            required
            :rules="mail_start_atRules"
        >
            <template #dateIcon>
                Date
            </template>
            <template #timeIcon>
                Time
            </template>
        </v-datetime-picker>
        <!-- Subject -->
        <v-text-field
            v-model="mail_subject"
            label="Subject"
            :rules="mail_subjectRules"
            outlined
            required
        />
        <!-- Header -->
        <v-text-field
            v-model="header"
            label="Header"
            :rules="headerRules"
            outlined
            required
        />
        <!-- Body -->
        <p class="mb-2">
            Body
        </p>
        <editor
            v-model="section1"
            api-key="xxpyp1va9hhcpkd0sc5ujku61f8lc2gmyk3hs60mzcbb9fwf"
            :init="{
                height: 300,
                menubar: false,
                images_upload_handler: uploadImageFromEditor,
                plugins: [
                    'lists link image paste help wordcount image'
                ],
                toolbar: 'undo redo | formatselect | bold italic backcolor | \
                    alignleft aligncenter alignright alignjustify | \
                    image'
            }"
        />
        <!-- Button Text -->
        <v-text-field
            v-model="button.text"
            class="mt-7"
            label="Button Text"
            :rules="buttonRules"
            outlined
            required
        />
        <!-- Button link -->
        <v-text-field
            v-model="button.url"
            label="Button Link"
            :rules="buttonRules"
            outlined
            required
        />
        <!-- subcopy -->
        <v-text-field
            v-model="subcopy"
            label="Footer"
            outlined
        />
        <v-btn
            :disabled="!valid"
            color="primary"
            class="mr-4"
            @click="submit"
        >
            Save
        </v-btn>
    </v-form>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import dayjs from 'dayjs'
import { mapEmailBuilderFormFields } from '@/store/modules/emailBuilder/'
import { eventBus } from '@/helpers/event-bus/'
import Api from '@/helpers/api/index.js'

export default {
    components: {
        Editor
    },
    data() {
        return {
            valid: false,
            mail_subjectRules: [
                (v) => !!v || 'mail_subject is required'
            ],
            headerRules: [
                (v) => !!v || 'Header is required'
            ],
            section1Rules: [
                (v) => !!v || 'Section1 is required'
            ],
            buttonRules: [
                (v) => !!v || 'is required',
                (v) => !!v || 'is required'
            ],
            mail_start_atRules: [
                (v) => !!v || 'is required'
            ]
        }
    },
    computed: {
        ...mapEmailBuilderFormFields({
            mail_subject: 'form_data.mail_subject',
            header: 'form_data.header-01',
            section1: 'form_data.section-01',
            button: 'form_data.button',
            subcopy: 'form_data.subcopy',
            mail_start_at: 'form_data.mail_start_at'
        })
    },
    created() {
        if (this.mail_start_at) {
            this.mail_start_at = dayjs(this.mail_start_at).toDate()
        } else {
            this.mail_start_at = ''
        }
    },
    methods: {
        submit() {
            this.$refs.form.validate()
            this.$nextTick(() => {
                if (this.valid) {
                    this.$emit('submitEmailForm')
                }
            })
        },
        async uploadImageFromEditor(blobInfo, success, failure) {
            const formData = new FormData();
            formData.append('file', blobInfo.blob(), blobInfo.filename());
            try {
                const { data } = await Api.post('/api/file-storage/mail/upload-image', formData)
                success(data)
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
                failure('error')
            }
        }
    }
}
</script>

<style>

</style>
