var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-datetime-picker',{attrs:{"label":"Start at","text-field-props":{
            outlined: true
        },"required":"","rules":_vm.mail_start_atRules},scopedSlots:_vm._u([{key:"dateIcon",fn:function(){return [_vm._v(" Date ")]},proxy:true},{key:"timeIcon",fn:function(){return [_vm._v(" Time ")]},proxy:true}]),model:{value:(_vm.mail_start_at),callback:function ($$v) {_vm.mail_start_at=$$v},expression:"mail_start_at"}}),_c('v-text-field',{attrs:{"label":"Subject","rules":_vm.mail_subjectRules,"outlined":"","required":""},model:{value:(_vm.mail_subject),callback:function ($$v) {_vm.mail_subject=$$v},expression:"mail_subject"}}),_c('v-text-field',{attrs:{"label":"Header","rules":_vm.headerRules,"outlined":"","required":""},model:{value:(_vm.header),callback:function ($$v) {_vm.header=$$v},expression:"header"}}),_c('p',{staticClass:"mb-2"},[_vm._v(" Body ")]),_c('editor',{attrs:{"api-key":"xxpyp1va9hhcpkd0sc5ujku61f8lc2gmyk3hs60mzcbb9fwf","init":{
            height: 300,
            menubar: false,
            images_upload_handler: _vm.uploadImageFromEditor,
            plugins: [
                'lists link image paste help wordcount image'
            ],
            toolbar: 'undo redo | formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                image'
        }},model:{value:(_vm.section1),callback:function ($$v) {_vm.section1=$$v},expression:"section1"}}),_c('v-text-field',{staticClass:"mt-7",attrs:{"label":"Button Text","rules":_vm.buttonRules,"outlined":"","required":""},model:{value:(_vm.button.text),callback:function ($$v) {_vm.$set(_vm.button, "text", $$v)},expression:"button.text"}}),_c('v-text-field',{attrs:{"label":"Button Link","rules":_vm.buttonRules,"outlined":"","required":""},model:{value:(_vm.button.url),callback:function ($$v) {_vm.$set(_vm.button, "url", $$v)},expression:"button.url"}}),_c('v-text-field',{attrs:{"label":"Footer","outlined":""},model:{value:(_vm.subcopy),callback:function ($$v) {_vm.subcopy=$$v},expression:"subcopy"}}),_c('v-btn',{staticClass:"mr-4",attrs:{"disabled":!_vm.valid,"color":"primary"},on:{"click":_vm.submit}},[_vm._v(" Save ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }