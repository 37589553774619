<template>
    <div
        v-click-outside="{
            handler: closeFilter,
            include: include,
        }"
        class="sticky-filter"
    >
        <v-card
            class="px-3 py-3 px-md-5"
            elevation="2"
        >
            <v-row class="align-center py-md-4 px-md-2">
                <div
                    class="filter_left d-flex align-center"
                >
                    <v-btn
                        color="success"
                        class="mr-3"
                        @click.stop="$emit('createForumClick')"
                    >
                        Create type
                    </v-btn>
                </div>
                <div
                    class="filter_btn d-flex justify-end align-center"
                    style="cursor: pointer;"
                >
                    <div
                        class="d-flex justify-end align-center"
                        @click="showFilters = !showFilters"
                    >
                        <div class="counter_wrapper">
                            <v-icon
                                color="#000"
                                size="35"
                            >
                                mdi-filter-outline
                            </v-icon>
                            <span
                                v-if="counter > 0"
                                class="ml-3 order_counter_filter"
                            >
                                {{ counter }}
                            </span>
                        </div>
                    </div>
                    <div
                        v-if="counter > 0"
                        class="mx-2"
                    >
                        <span
                            class="accent--text "
                            @click="reset"
                        >
                            RESET
                        </span>
                    </div>
                </div>
            </v-row>
        </v-card>
        <v-slide-x-reverse-transition>
            <v-card
                v-show="showFilters"
                :class="showFilters ? 'pt-4' : ''"
                dense
                elevation="2"
                class="sidebar-filter px-5 py-5"
            >
                <v-row class="align-center">
                    <v-col
                        cols="6"
                        class="d-flex align-center"
                    >
                        <span class="text-subtitle-1">FILTERS</span>
                        <span class="ml-3 sidebar-filter_counter">
                            {{ counter }}
                        </span>
                    </v-col>
                    <v-col cols="6">
                        <div class="d-flex justify-end">
                            <v-btn
                                class="mr-4"
                                @click="reset"
                            >
                                <v-icon>
                                    mdi-delete-outline
                                </v-icon>
                                CLEAR
                            </v-btn>
                            <v-btn
                                icon
                                @click="closeFilter"
                            >
                                <v-icon>
                                    mdi-close
                                </v-icon>
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>

                <v-row>
                    <v-row class="mt-5">
                        <!-- SEARCH BY -->
                        <v-col>
                            <div class="d-flex">
                                <v-select
                                    v-model="search_by"
                                    :items="fields"
                                    label="Search field"
                                    outlined
                                    class="search_field"
                                    hide-details
                                />
                                <v-text-field
                                    v-model="searchQuery"
                                    label="Search"
                                    prepend-inner-icon="mdi-magnify"
                                    outlined
                                    class="search_input"
                                    hide-details
                                />
                            </div>
                        </v-col>
                    </v-row>
                </v-row>
            </v-card>
        </v-slide-x-reverse-transition>
    </div>
</template>

<script>
import { debounce } from 'lodash'

import {
    SUPPORT_PRIORITY
} from '@/constants/Support'

import { statusBgColor, statusText, statusTextColor } from '@/helpers/order/statusHelper'

export default {
    props: {
        searchOptions: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            date: [],
            showFilters: false,
            search: {
                search_for: '',
                search_by: 'name'
            },
            counter: 0,
            search_by: 'name',
            searchQuery: ''
        }
    },
    computed: {
        fields() {
            return [
                {
                    text: 'Name',
                    value: 'name'
                }
            ]
        },
        priorities() {
            return SUPPORT_PRIORITY
        },
        searchDefaultState() {
            return {
                search_for: '',
                search_by: 'name'
            }
        }
    },
    watch: {
        search: {
            handler() {
                this.$emit('filterTable', this.search)
                this.counterFilters()
            },
            deep: true
        },
        search_by(val) {
            if (this.search.search_for) {
                this.search.search_by = val
            }
        },
        searchQuery: debounce(function (val) {
            this.search.search_for = val
            this.search.search_by = this.search_by
        }, 500)
    },
    created() {
        this.search = { ...this.search, ...this.searchOptions }
    },
    methods: {
        statusText,
        statusBgColor,
        statusTextColor,
        include() {
            if (this.showFilters) {
                return [...document.querySelectorAll('.v-menu__content')]
            }
            return []
        },
        getIcon(str) {
            switch (str) {
            case 'NORMAL':
                return 'status_medium.svg'
            case 'HIGH':
                return 'status_high.svg'
            case 'LOW':
                return 'status_low.svg'
            default:
                return ''
            }
        },
        reset() {
            this.search = { ...this.searchDefaultState }
            this.search_by = 'name'
            this.searchQuery = ''
        },
        counterFilters() {
            this.counter = Object.entries(this.searchDefaultState).reduce((acc, [key, val]) => {
                if (this.search[key] !== val) {
                    acc += 1
                }
                return acc
            }, 0)
        },
        closeFilter() {
            this.showFilters = false
        }
    }
}
</script>

<style lang="scss">
    .sidebar-filter{
        width: 420px;
        position: fixed;
        z-index: 9;
        top: 0;
        bottom: 0;
        right: 0;
        &_counter{
            padding: 5px 10px;
            font-weight: bold;
            background: rgba(255, 105, 12, 0.1);
            color: #82b1ff;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .search_field{
        max-width: 150px !important;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
    .search_input{
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }
    .filter_status_by{
        max-width: 120px;
    }
    .filter_btn{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
    }
    .filter_left{
        min-height: 20px;
        width: calc(100% - 65px);
        @media all and (max-width: 1099px){
            flex-wrap: wrap;
        }
        .filter_status{
            @media all and (max-width: 1099px){
                width: 100%;
            }
        }

    }
    .counter_wrapper{
        position: relative;
    }
    .order_counter_filter{
        position: absolute;
        display: block;
        width: 19px;
        height: 19px;
        border-radius: 50%;
        background: #284067;
        top: -7px;
        right: -4px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        color: #fff;
    }
    .btn_counter{
        padding: 5px 10px;
        border-radius: 4px;
        margin-left: 10px;
        font-weight: bold;
        text-align: center;
        &--All {
            background: #f2f2f2;
            color: #000;
        }
        &--active, &--open{
            background: rgba(89, 199, 77, 0.1);
            color: #59C74D;
        }
        &--inactive, &--closed {
            background: rgba(212, 28, 28, 0.1);
            color: #D41C1C;
        }
    }
    .search_field{
        max-width: 150px !important;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
    .search_input{
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }
    .support_filter_btn{
        // margin-top: -50px;
        position: relative;
        margin-left: auto;
        width: max-content;
    }
</style>
