export const TYPES_TABLE_HEAD = [
    {
        text: 'In-x',
        value: 'index',
        width: '60px',
        align: 'center',
        sortable: false
    },
    {
        text: 'Name',
        value: 'name',
        width: '220px'
    },
    {
        text: 'Created',
        value: 'created_at',
        sortable: true,
        width: '220px'
    },
    {
        text: 'Creator',
        sortable: true,
        value: 'admin_id'
    },
    {
        text: 'Status',
        value: 'status_active',
        sortable: false,
        width: '90px'
    },
    {
        text: '',
        sortable: false,
        width: '55px'
    }
]

export const TYPES_TOOLBARS = [
    {
        name: 'activate',
        text: 'Activate',
        modal: {
            name: 'ModalAlert',
            title: 'Project type activation',
            description: 'Do you want to activate selected project type?',
            success_message: 'The project type is activated'
        },
        icon: 'mdi-checkbox-marked-circle-outline'
    },
    {
        name: 'deactivate',
        text: 'Deactivate',
        modal: {
            name: 'ModalAlert',
            title: 'Project type deactivation',
            description: 'Do you want to deactivate selected project type?',
            success_message: 'The project type is deactivated'
        },
        icon: 'mdi-close-circle-outline'
    },
    {
        name: 'edit',
        text: 'Edit',
        modal: {
            name: 'ModalTextField',
            title: 'Project type edit',
            inputLabel: 'New project type name',
            success_message: 'Project type data edited'
        },
        icon: 'mdi-pencil'
    }
]
